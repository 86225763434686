import React from 'react';
import { Box, Skeleton } from '@mui/material';

const ProductSkeleton = () => {
  return (
    <Box className="w-full md:w-[90%] flex flex-col md:flex-row mx-auto my-4 px-5 md:px-5 tracking-normal">
      <Box className="md:w-1/2 tracking-normal">
        <Skeleton variant="rectangular" width="100%" height="400px" sx={{ borderRadius: '16px' }} />
        <Box className="hidden md:flex mt-4">
          {[...Array(3)].map((_, index) => (
            <Skeleton key={index} variant="rectangular" width="30%" height="100px" sx={{ borderRadius: '8px', marginRight: '10px' }} />
          ))}
        </Box>
      </Box>
      <Box className="md:ml-10 text-md md:w-1/2">
        <Skeleton variant="text" width="60%" height={40} sx={{ marginBottom: '10px' }} />
        <Skeleton variant="text" width="90%" height={20} sx={{ marginBottom: '10px' }} />
        <Skeleton variant="text" width="90%" height={20} sx={{ marginBottom: '10px' }} />
        <Skeleton variant="text" width="40%" height={20} sx={{ marginBottom: '10px' }} />
        <Skeleton variant="text" width="30%" height={30} sx={{ marginBottom: '20px' }} />
        <Skeleton variant="rectangular" width="80%" height={40} sx={{ borderRadius: '20px', marginBottom: '20px' }} />
        <Skeleton variant="rectangular" width="80%" height={40} sx={{ borderRadius: '20px', marginBottom: '20px' }} />
        <Skeleton variant="rectangular" width="80%" height={40} sx={{ borderRadius: '20px', marginBottom: '20px' }} />
      </Box>
    </Box>
  );
};

export default ProductSkeleton;
