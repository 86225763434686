import React, { useState } from "react";
import { RiCloseLargeFill, RiDeleteBin6Line } from "react-icons/ri";
import { Link } from "react-router-dom";
import Quantity from "../../components/Cart/cartQuantity";
import { useSelector, useDispatch } from "react-redux";
import { INCREMENT_QUANTITY, DECREMENT_QUANTITY } from "../../redux/types/cart.types";
import { cartActions } from "../../redux/slice/cart.slice";
import { authActions } from "../../redux/slice/auth.slice";

const Cart = ({ setIsCartSidebarOpen }) => {
  const cartItems = useSelector(cartActions.selectors.getCartItems);
  const dispatch = useDispatch();
  
  const handleIncrement = (id) => {
    dispatch({ type: INCREMENT_QUANTITY, payload: id });
  };

  const handleDecrement = (id) => {
    dispatch({ type: DECREMENT_QUANTITY, payload: id });
  };

  const currentUser = useSelector(authActions.selectors.getCurrentUser)


  const close = () => {
    setIsCartSidebarOpen(false)
  }

  const handleRemove = (id) => {
    console.log(id)
    dispatch(cartActions.actions.removeFromCart(id));
  }

  const API_URL = process.env.REACT_APP_API_URL;

  return (
    <div>
      <div className="overflow-y-auto h-screen w-[80] md:w-[420px] bg-white px-2 py-4 tracking-normal">
        <button
          onClick={() => {
            setIsCartSidebarOpen(false);
          }}
          className="fixed top-0 right-0  px-4 py-2 rounded-md my-2 ml-2 "
        >
          <RiCloseLargeFill size={30} />
        </button>
        {cartItems.length === 0 ? (
          <div className="h-screen flex flex-col justify-center text-center  ">
            <div className="py-2">
              <h2 className="text-2xl ">Your cart is empty</h2>
              <button className="text-base mt-6 bg-primary cursor-pointer border-primary hover:border-2 text-white  py-3 px-10 rounded-full focus:outline-none focus:shadow-outline" onClick={close}>
              <Link to={"/"}>
                Continue Shopping
              </Link>
              </button>
              {!currentUser?.username ? 
              <div className="mt-12">
                <h2 className="text-xl ">Have an account?</h2>
                <p className=" text-gray-700 text-lg">
                  {" "}
                  <span>
                    {" "}
                    <Link to={"/login"}>
                      <a className=" underline border-primary  inline-block align-baseline  text-primary hover:text-md">
                        Log in
                      </a>
                    </Link>
                  </span>{" "}
                  to checkout faster.
                </p>
              </div>:null}
            </div>
          </div>
        ) : (
          <div className="overflow-y-auto h-full">
            <div className="mx-3 pt-4 flex flex-col justify-between  h-[95%]">
              <div>
              <h1 className="text-2xl ">Your Cart</h1>
              <table className="mt-4  ">
                <thead className="">
                  <tr className=" ">
                    <th className="py-2 flex items-start font-medium text-secondary text-sm">
                      Product
                    </th>
                    <th className="py-2  font-medium text-secondary text-sm"></th>
                    <th className="py-2  font-medium text-secondary text-sm">
                      Total
                    </th>
                  </tr>
                </thead>
                <tbody className=" border-t">
                  {cartItems.map((item, index) => (
                    <tr key={index} className="border-t border-gray-200 ">
                      <td className=" flex justify-start py-5">
                        <img
                          src={`${API_URL}${item.product.images?.[0]}`}
                          alt={item.product.name}
                          className=" w-24 h-40"
                          style={{objectFit: 'cover'}}
                        />
                      </td>
                      <td className="py-5 text-sm sm:text-[18px]">
                        <div className=" pl-4 -mt-1">
                          <span>
                          <h1 className="text-sm sm:text-[18px] hover:underline cursor-pointer">{item.product.name}</h1>
                          <p>Price: £{item.product.price}</p>
                          <p>Color: {item?.selectedColor}</p>
                          <p>Size: {item?.selectedSize}</p>
                          </span>
                          <div className="flex items-center flex-wrap">
                            <Quantity
                              quantity={item.quantity}
                              onIncrement={() => handleIncrement(item.product._id)}
                              onDecrement={() => handleDecrement(item.product._id)}
                            />
                            <RiDeleteBin6Line className=" cursor-pointer ml-2 sm:ml-4 text-primary" onClick={()=>handleRemove(item.product?._id)}/>
                          </div>
                        </div>
                      </td>
                      <td className=" sm:px-2 px-0 text-sm sm:text-[18px] align-top py-4">£ {item.product.price * item.quantity}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              </div>
              <div className="mx-1 border-t">
                <div className="my-2 flex items-center justify-between py-1">
                  <h1 className="text-lg">Subtotal</h1>
                  <h1 className="text-lg">
                    £{cartItems.reduce((acc, item) => acc + item.product.price * item.quantity, 0)} GBP
                  </h1>
                </div>
                {/* <p className="mb-2">
                  Tax included.{" "}
                  <span className="underline text-primary cursor-pointer">
                    Shipping
                  </span>{" "}
                  calculated at checkout.
                </p> */}
                <Link to={"/checkout"}>
                  <button
                    onClick={() => {
                      setIsCartSidebarOpen(false);
                    }}
                    className="w-full text-base  bg-primary cursor-pointer border-primary hover:border-2 text-white  py-3 px-10 rounded-full focus:outline-none focus:shadow-outline"
                  >
                    Check Out
                  </button>
                </Link>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Cart;
