import React from "react";
import { Link } from "react-router-dom";
import "../EidCollection/EidCollection.css";
import ProductCard from "./ProductsCard";

const Products = ({ heading, para, products, showFour = true, id }) => {
  const displayedProducts = showFour ? products.slice(0, 4) : products;
  const API_URL = process.env.REACT_APP_API_URL;

  return (
    <div className="collection-container">
      <h1 className="collection-title">{heading}</h1>
      <p className="collection-subtitle">{para}</p>
      <div className="w-[100%] mx-auto grid sm:grid-cols-4 grid-cols-2 gap-x-4 ">{/*changed width 90% to 100% by sohaib*/}
        {displayedProducts.map((product) => (
          <div key={product._id}>
            <Link to={`/products/${product._id}`}>
              <ProductCard
                image={`${API_URL}${product.images[0]}`} // Assuming the first image is the main image
                hoverImage={`${API_URL}${product.images[1]}`} // Assuming the second image is the hover image
                price={product.price}
                title={product.name}
                inStock={product?.stock > 0} 
              />
            </Link>
          </div>
        ))}
      </div>
      <div className="view-all-container">
        <Link to={`/collections/${id}`}>
          <p className="view-all-link">View all</p>
        </Link>
      </div>
    </div>
  );
};

export default Products;
