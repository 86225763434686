import React from "react";
import Slider from "react-slick";
import './ImageSlider.css';

function ImageSlider({ images, onImageClick }) {
  const settings = {
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  const API_URL = process.env.REACT_APP_API_URL
  
  return (
    <div className="slider-contain mt-2">
      <Slider {...settings} className="h-24 flex space-x-2">
        {images.map((image, index) => (
          <div className="p-2" key={index}>
            <img
              className="slider-images"
              src={`${API_URL}${image}`}
              alt={`Slide ${index + 1}`}
              onClick={() => onImageClick(image)}
            />
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default ImageSlider;

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "white", borderRadius: "50%" }}
      onClick={onClick}
    >
      <span style={{ color: "black" }}>&#9654;</span>
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "white", borderRadius: "50%" }}
      onClick={onClick}
    >
      <span style={{ color: "black" }}>&#9664;</span>
    </div>
  );
}
