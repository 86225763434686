import React, { useEffect, useState } from "react";
import { useForm, useWatch, Controller } from "react-hook-form";
import { useSelector } from "react-redux";
import { Country, State, City } from "country-state-city";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { setLocale } from "yup";
import { en } from "yup-locales";
import Select from "react-select";
import {
  parsePhoneNumberFromString,
  getExampleNumber,
} from "libphonenumber-js";
import { authActions } from "../../redux/slice/auth.slice";
import examples from "libphonenumber-js/examples.mobile.json";
import { formatIncompletePhoneNumber } from "libphonenumber-js";

// Set the locale for yup validation messages
setLocale(en);

const getValidPhoneNumberFormat = (countryCode) => {
  try {
    const exampleNumber = examples[countryCode];
    if (exampleNumber) {
      // Format the number using the library's formatting function
      const formattedNumber = formatIncompletePhoneNumber(
        exampleNumber,
        countryCode
      );
      return formattedNumber;
    } else {
      return "No valid format available";
    }
  } catch (error) {
    return "Invalid country code";
  }
};

const CheckoutForm = ({ handlePlaceOrder, orderStatus, order }) => {
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(
      yup.object().shape({
        email: yup.string().email().required("Email is required"),
        country: yup.object().shape({
          value: yup.string().required("Country is required"),
        }),
        state: yup.object().shape({
          value: yup.string().required("State is required"),
        }),
        firstName: yup.string().required("First Name is required"),
        lastName: yup.string().required("Last Name is required"),
        address: yup.string().required("Address is required"),
        postCode: yup.string().required("Postal Code is required"),
        phone: yup
          .string()
          .required("Phone is required")
          .test("phone-format", function (value) {
            const selectedCountry = this.parent.country.value;
            if (selectedCountry && value) {
              const phoneNumber = parsePhoneNumberFromString(
                value,
                selectedCountry
              );
              if (!phoneNumber || !phoneNumber.isValid()) {
                return this.createError({
                  message: `Invalid phone number. Expected format: ${getValidPhoneNumberFormat(
                    selectedCountry
                  )}`,
                });
              }
              return true;
            }
            return false;
          }),
      })
    ),
  });

  const currentUser = useSelector(authActions.selectors.getCurrentUser);

  const countries = Country.getAllCountries().map((country) => ({
    value: country.isoCode,
    label: country.name,
  }));

  const selectedCountry = useWatch({ control, name: "country" });
  const selectedState = useWatch({ control, name: "state" });

  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  useEffect(() => {
    if (selectedCountry) {
      const statesData = State.getStatesOfCountry(selectedCountry.value);
      setStates(
        statesData.map((state) => ({ value: state.isoCode, label: state.name }))
      );
      setCities([]);
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (selectedState) {
      const citiesData = City.getCitiesOfState(
        selectedCountry.value,
        selectedState.value
      );
      setCities(
        citiesData.map((city) => ({ value: city.name, label: city.name }))
      );
    }
  }, [selectedState, selectedCountry]);

  useEffect(() => {
    if (orderStatus === "succeeded") {
      reset(); // Reset the form after a successful order
    }
  }, [orderStatus, reset]);

  return (
    <form onSubmit={handleSubmit((data) => handlePlaceOrder(data))}>
      <div className="md:w-4/5 mx-auto mt-10 grid grid-cols-1 gap-x-6 gap-y-4 checkout-form-font">
        <div className="flex justify-between mb-2">
          <h1 className="text-xl font-semibold">Contact</h1>
          {!currentUser?.username && (
            <a href="/login" className="underline text-blue-700">
              Log in
            </a>
          )}
        </div>

        <div>
          <label
            htmlFor="email"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Email address
          </label>
          <div className="mt-2">
            <input
              id="email"
              name="email"
              type="email"
              autoComplete="email"
              className="w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 p-2"
              {...register("email")}
            />
            {errors.email && (
              <span className="text-red-500">{errors.email.message}</span>
            )}
          </div>
        </div>

        <div className="pl-2 mt-[-5px] flex gap-x-3">
          <div className="flex h-6 items-center">
            <input
              id="comments"
              name="comments"
              type="checkbox"
              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
              {...register("comments")}
            />
          </div>
          <div className="text-sm leading-6">
            <label htmlFor="comments" className="font-medium text-gray-900">
              Email me with news and offers
            </label>
          </div>
        </div>

        <h1 className="text-xl font-semibold mt-2">Delivery</h1>

        <div className="w-full">
          <label
            htmlFor="country"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Country
          </label>
          <div className="mt-2">
            <Controller
              name="country"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  options={countries}
                  className="w-full"
                  isSearchable
                  placeholder="Select Country"
                />
              )}
            />
            {errors.country?.value && (
              <span className="text-red-500">
                {errors.country.value.message}
              </span>
            )}
          </div>
        </div>

        {selectedCountry && (
          <div className="w-full">
            <label
              htmlFor="state"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              State
            </label>
            <div className="mt-2">
              <Controller
                name="state"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={states}
                    className="w-full"
                    isSearchable
                    placeholder="Select State"
                  />
                )}
              />
              {errors.state?.value && (
                <span className="text-red-500">
                  {errors.state.value.message}
                </span>
              )}
            </div>
          </div>
        )}

        {selectedState && (
          <div className="w-full">
            <label
              htmlFor="city"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              City
            </label>
            <div className="mt-2">
              <Controller
                name="city"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={cities}
                    className="w-full"
                    isSearchable
                    placeholder="Select City"
                  />
                )}
              />
              {errors.city?.value && (
                <span className="text-red-500">
                  {errors.city.value.message}
                </span>
              )}
            </div>
          </div>
        )}

        <div className="grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6">
          <div className="sm:col-span-3">
            <label
              htmlFor="firstName"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              First name
            </label>
            <div className="mt-2">
              <input
                type="text"
                name="firstName"
                id="firstName"
                autoComplete="given-name"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 p-2"
                {...register("firstName")}
              />
              {errors.firstName && (
                <span className="text-red-500">{errors.firstName.message}</span>
              )}
            </div>
          </div>

          <div className="sm:col-span-3">
            <label
              htmlFor="lastName"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Last name
            </label>
            <div className="mt-2">
              <input
                type="text"
                name="lastName"
                id="lastName"
                autoComplete="family-name"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 p-2"
                {...register("lastName")}
              />
              {errors.lastName && (
                <span className="text-red-500">{errors.lastName.message}</span>
              )}
            </div>
          </div>

          <div className="col-span-full">
            <label
              htmlFor="address"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Address
            </label>
            <div className="mt-2">
              <input
                type="text"
                name="address"
                id="street-address"
                autoComplete="street-address"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 p-2"
                {...register("address")}
              />
              {errors.address && (
                <span className="text-red-500">{errors.address.message}</span>
              )}
            </div>
          </div>

          <div className="col-span-full">
            <label
              htmlFor="appartment"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Apartment, Suite, etc. (optional)
            </label>
            <div className="mt-2">
              <input
                type="text"
                name="appartment"
                id="appartment"
                autoComplete="appartment"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 p-2"
                {...register("appartment")}
              />
            </div>
          </div>

          <div className="col-span-full">
            <label
              htmlFor="postCode"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Postal Code
            </label>
            <div className="mt-2">
              <input
                type="text"
                name="postCode"
                id="postCode"
                autoComplete="postal-code"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 p-2"
                {...register("postCode")}
              />
              {errors.postCode && (
                <span className="text-red-500">{errors.postCode.message}</span>
              )}
            </div>
          </div>

          <div className="col-span-full">
            <label
              htmlFor="phone"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Phone
            </label>
            <div className="mt-2">
              <input
                type="tel"
                name="phone"
                id="phone"
                autoComplete="phone"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 p-2"
                {...register("phone")}
              />
              {errors.phone && (
                <span className="text-red-500">
                  {errors.phone.message || "Invalid phone number format"}
                </span>
              )}
            </div>
          </div>
        </div>

        <div className="flex justify-end mt-4 mr-12 w-full">
          {!order ? (
            <button
              type="submit"
              className="bg-blue-500 text-white py-2 w-full px-4 rounded"
            >
              Place Order
            </button>
          ) : null}
        </div>
      </div>
    </form>
  );
};

export default CheckoutForm;
