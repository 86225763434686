// components/WhatsAppButton/WhatsAppButton.jsx
import React from "react";
import WhatsAppIcon from "@mui/icons-material/WhatsApp"; // Make sure to install @mui/icons-material if you haven't already

const WhatsAppButton = () => {
  return (
    <a
      href="https://wa.me/447440167549" // Replace 'yourNumber' with your actual WhatsApp number
      target="_blank"
      rel="noopener noreferrer"
      style={{
        position: "fixed",
        bottom: "20px",
        right: "20px",
        backgroundColor: "#25D366",
        borderRadius: "50%",
        width: "60px",
        height: "60px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: "1000",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)"
      }}
    >
      <WhatsAppIcon style={{ color: "white", fontSize: "30px" }} />
    </a>
  );
};

export default WhatsAppButton;
