import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import CheckoutForm from "../../components/CheckoutForm/CheckoutForm";
import { IoIosArrowDown } from "react-icons/io";
import { cartActions } from "../../redux/slice/cart.slice";
import { ADD_ORDER } from "../../redux/types/order.types";
import { orderActions } from "../../redux/slice/order.slice";
import { authActions } from "../../redux/slice/auth.slice";
import toast from "react-hot-toast";
import PayPal from "../../components/PayPal/PayPal";

const CheckOut = () => {
  const [showOrders, setShowOrders] = useState(false);
  const cartItems = useSelector(cartActions.selectors.getCartItems);
  const dispatch = useDispatch();
  const orderStatus = useSelector(orderActions.selectors.getOrderStatus);

  let subTotal = 0;
  cartItems.forEach((item) => {
    subTotal += item.quantity * item.product.price;
  });

  const API_URL = process.env.REACT_APP_API_URL;

  const currentUser = useSelector(authActions.selectors.getCurrentUser);

  const [order, setOrder] = useState(null);

  const handlePlaceOrder = (formData) => {
    if (!currentUser?.username) {
      toast.error("Login first to place order");
    } else {
      const orderData = {
        user: currentUser?._id, // Assuming you have user info in the form data
        items: cartItems.map((item) => ({
          product: item.product._id,
          quantity: item.quantity,
          color: item.selectedColor,
          size: item.selectedSize,
          price: item.product.price,
          name: item.product.name
        })),
        total: subTotal,
        status: "pending",
        country: formData?.country?.label, // Assuming formData.country is already the string
        state: formData?.state?.label, // Assuming formData.state is already the string
        city: formData?.city?.label, // Assuming formData.city is already the string
        firstName: formData?.firstName,
        lastName: formData?.lastName,
        address: formData?.address,
        postCode: formData?.postCode,
        phone: formData?.phone,
        email: formData?.email,
        comments: formData?.comments,
      };

      console.log(orderData);
      setOrder(orderData)
      // dispatch({ type: ADD_ORDER, payload: orderData });
    }
  };

  return (
    <div className="md:flex flex-row-reverse border-t ">
      <div className=" h-auto w-full md:w-1/2 bg-gray-200 p-6 border-b md:border-l ">
        <div className="hidden md:flex w-full md:w-4/5">
          <table className="text-sm mt-4 w-full">
            <tbody className="mt-6">
              {cartItems.map((item, index) => (
                <tr key={index} className="border-b border-gray-300">
                  <td className="py-2 w-0 pr-3">
                    <div className="px-1 bg-gray-100 w-16 h-20 itmes-center object-contain border border-gray-400 rounded-lg ">
                      <img
                        src={`${API_URL}${item.product.images?.[0]}`}
                        alt={item.product.name}
                        className=" h-full w-full"
                      />
                    </div>
                  </td>
                  <td className=" text-sm ">
                    <div className="">
                      <h1>{item.product.name}</h1>
                      <p className="text-secondary">
                        Size: {item.selectedSize}
                      </p>
                      <p className="text-secondary">
                        Color: {item.selectedColor}
                      </p>
                      <p className="text-secondary">
                        Quantity: {item.quantity}
                      </p>
                    </div>
                  </td>
                  <td className="py-2 text-sm text-end align-top">
                    £ {item.quantity * item.product.price}
                  </td>
                </tr>
              ))}

              <tr className="">
                <td className="pt-2">Subtotal</td>
                <td className="pt-2 "></td>
                <td className="pt-2 px-2 text-sm text-end">£ {subTotal}</td>
              </tr>
              <tr className="">
                <td className="py-1 w-[100px]">Shipping Fee</td>
                <td className="py-1 "></td>
                <td className="py-1 px-2 text-sm text-end">
                  £ {subTotal && 12}
                </td>
              </tr>
              <tr className="">
                <td className="py-1">
                  <h1 className="text-base font-semibold">Total</h1>
                </td>
                <td className="py-1 "></td>
                <td className="py-1 px-2 text-sm text-end">
                  GBP{" "}
                  <span className="text-base font-semibold">
                    £ {subTotal + (subTotal && 12)}{" "}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div
          className="flex justify-between md:hidden"
          onClick={() => {
            setShowOrders(!showOrders);
          }}
        >
          <h1 className="text-sm sm:text-lg text-blue-500 flex items-center">
            Show Order Summary{" "}
            <span className="ml-0 sm:ml-2">
              {" "}
              <IoIosArrowDown />{" "}
            </span>{" "}
          </h1>
          <h1 className=" font-semibold">£{subTotal} </h1>
        </div>
        {showOrders && (
          <div className="w-full md:w-4/5">
            <table className="text-sm mt-4 w-full">
              <tbody className="mt-6 ">
                {cartItems.map((item, index) => (
                  <tr key={index} className="">
                    <td className="">
                      <div className="px-1  bg-gray-100 w-16 h-20 itmes-center object-contain border border-gray-400 rounded-lg">
                        {/* <img
                          src={item.product.image}
                          alt={item.product.name}
                          className=" "
                        /> */}
                          <img
                        src={`${API_URL}${item.product.images?.[0]}`}
                        alt={item.product.name}
                        className=" h-full w-full"
                      />
                      {/* copy this image from the last section */}
                      </div>
                    </td>
                    <td className="py-2 text-[80%] sm:text-[100%]  w-28" >
                      <div>
                        <h1>{item.product.name}</h1>
                        <p className="text-secondary">
                          Size: {item.selectedSize}
                        </p>
                        <p className="text-secondary">
                          Color: {item.selectedColor}
                        </p>
                        <p className="text-secondary">
                          Quantity: {item.quantity}
                        </p>
                      </div>
                    </td>
                    <td className="py-2 text-[80%] sm:text-[100%] text-end align-top">
                      £ {item.quantity * item.product.price}
                    </td>
                  </tr>
                ))}

                <tr className="">
                  <td className="pt-2">Subtotal</td>
                  <td className="pt-2 "></td>
                  <td className="pt-2 px-2 text-sm text-end">
                    £ {subTotal}.00
                  </td>
                </tr>
                <tr className="">
                  <td className="py-3 align-top">Shipping</td>
                  <td className="py-3"></td>
                  <td className="py-3 px-2 text-sm text-end ">
                  £ 12.00
                  </td>
                </tr>
                <tr className="">
                  <td className="py-1 align-top ">
                    <h1 className="text-base font-semibold ">Total</h1>
                  </td>
                  <td className="py-1 "></td>
                  <td className="py-1 sm:px-2 px-0 text-[80%] sm:text-[100%] text-end">
                    GBP{" "}
                    <span className="text-base font-semibold">£ {subTotal + (subTotal && 12)}{" "}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </div>
      <div className="w-full md:w-1/2 p-10 flex flex-col">
        <CheckoutForm
          handlePlaceOrder={handlePlaceOrder}
          orderStatus={orderStatus}
          order={order}
        />
        <div style={{ paddingLeft: "10%", paddingRight: "10%" }}>
          {order && subTotal + (subTotal && 12) ? (
            <PayPal amount={(subTotal + (subTotal && 12)).toString()} order={order} />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default CheckOut;
