import React, { useState } from "react";
import { CiSearch } from "react-icons/ci";
import Anouncement from "./Anouncement";

import Nav from "../Nav/Nav";
import { Link } from "react-router-dom";
import Cart from "../../pages/Cart/Cart";
import MobileNavbar from "../MobileNavbar/MobileNavbar";
import Search from "../Search/Search";
import { useSelector } from "react-redux";
import { authActions } from "../../redux/slice/auth.slice";
import { cartActions } from "../../redux/slice/cart.slice";

const Header = () => {
  const [isSideBarOpen, setIsSideBarOpen] = useState(false);
  const [isCartSidebarOpen, setIsCartSidebarOpen] = useState(false);
  const [isSearchBarOpen, setIsSearchBarOpen] = useState(false);

  const currentUser = useSelector(authActions.selectors.getCurrentUser);
  const cartItems = useSelector(cartActions.selectors.getCartItems);

  return (
    <>
      <Anouncement />

      <div className="flex items-center justify-between sm:px-0 lg:px-0 py-0 mx-auto max-w-screen-xl">
        <div className="flex items-center justify-start w-1/3">
          <button
            onClick={() => setIsSideBarOpen(!isSideBarOpen)}
            type="button"
            className="inline-flex items-center p-2 ml-1 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
            aria-controls="mobile-menu-2"
            aria-expanded={isSideBarOpen}
          >
            <span className="sr-only">Open main menu</span>
            <svg
              className={`${!isSideBarOpen ? "" : "hidden"} w-6 h-6`}
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                clipRule="evenodd"
              ></path>
            </svg>
            <svg
              className={`${isSideBarOpen ? "" : "hidden"} w-6 h-6`}
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
          </button>
        </div>

        <div className="flex justify-center w-1/3">
          <Link to={"/"}>
            <img
              className="w-[150px] md:w-[200px]"
              src="../logo.png"
              alt="logo"
            />
          </Link>
        </div>

        <div className="flex items-center justify-end w-1/3 gap-2">
          {currentUser?.username ? (
            <Link to={"/account"}>
              <svg
                className="h-4 w-4 md:h-6 md:w-6 cursor-pointer"
                xmlns="http://www.w3.org/2000/svg"
                focusable="false"
                role="presentation"
                fill="none"
                viewBox="0 0 18 19"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M6 4.5a3 3 0 116 0 3 3 0 01-6 0zm3-4a4 4 0 100 8 4 4 0 000-8zm5.58 12.15c1.12.82 1.83 2.24 1.91 4.85H1.51c.08-2.6.79-4.03 1.9-4.85C4.66 11.75 6.5 11.5 9 11.5s4.35.26 5.58 1.15zM9 10.5c-2.5 0-4.65.24-6.17 1.35C1.27 12.98.5 14.93.5 18v.5h17V18c0-3.07-.77-5.02-2.33-6.15-1.52-1.1-3.67-1.35-6.17-1.35z"
                  fill="currentColor"
                ></path>
              </svg>
            </Link>
          ) : (
            <Link to={"/login"}>
              <svg
                className="h-4 w-4 md:h-6 md:w-6 cursor-pointer"
                xmlns="http://www.w3.org/2000/svg"
                focusable="false"
                role="presentation"
                fill="none"
                viewBox="0 0 18 19"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M6 4.5a3 3 0 116 0 3 3 0 01-6 0zm3-4a4 4 0 100 8 4 4 0 000-8zm5.58 12.15c1.12.82 1.83 2.24 1.91 4.85H1.51c.08-2.6.79-4.03 1.9-4.85C4.66 11.75 6.5 11.5 9 11.5s4.35.26 5.58 1.15zM9 10.5c-2.5 0-4.65.24-6.17 1.35C1.27 12.98.5 14.93.5 18v.5h17V18c0-3.07-.77-5.02-2.33-6.15-1.52-1.1-3.67-1.35-6.17-1.35z"
                  fill="currentColor"
                ></path>
              </svg>
            </Link>
          )}
          <div className="relative">
            <svg
              onClick={() => setIsCartSidebarOpen(!isCartSidebarOpen)}
              className="h-12 w-12 cursor-pointer"
              aria-hidden="true"
              focusable="false"
              role="presentation"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 40 40"
              fill="none"
            >
              <path
                d="m15.75 11.8h-3.16l-.77 11.6a5 5 0 0 0 4.99 5.34h7.38a5 5 0 0 0 4.99-5.33l-.78-11.61zm0 1h-2.22l-.71 10.67a4 4 0 0 0 3.99 4.27h7.38a4 4 0 0 0 4-4.27l-.72-10.67h-2.22v.63a4.75 4.75 0 1 1 -9.5 0zm8.5 0h-7.5v.63a3.75 3.75 0 1 0 7.5 0z"
                fill="currentColor"
                fillRule="evenodd"
              ></path>
            </svg>
            {cartItems.length > 0 && (
              <div
                className=" absolute top-0 right-0 text-white rounded-full h-5 w-5 flex items-center justify-center text-xs"
                style={{ backgroundColor: "black" }}
              >
                {cartItems.length}
              </div>
            )}
          </div>
        </div>
      </div>

      {isSideBarOpen && (
        <div className="fixed inset-0 z-50 flex bg-gray-900 bg-opacity-50">
          <MobileNavbar setIsSideBarOpen={setIsSideBarOpen} />
          <div className="flex-1" onClick={() => setIsSideBarOpen(false)}></div>
        </div>
      )}

      {isCartSidebarOpen && (
        <div className="fixed inset-0 z-50 flex flex-row-reverse bg-gray-900 bg-opacity-50">
          <Cart setIsCartSidebarOpen={setIsCartSidebarOpen} />
          <div
            className="flex-1"
            onClick={() => setIsCartSidebarOpen(false)}
          ></div>
        </div>
      )}

      {isSearchBarOpen && (
        <div className="fixed mt-[36px] inset-0 z-40 flex flex-col  bg-gray-900 bg-opacity-50">
          <Search setIsSearchBarOpen={setIsSearchBarOpen} />
          <div
            className="flex-1 flex-col"
            onClick={() => setIsSearchBarOpen(false)}
          ></div>
        </div>
      )}
    </>
  );
};

export default Header;
