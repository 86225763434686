// src/redux/slice/order.slice.js
import { createSelector, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";

const initialState = {
  orders: [],
  status: "idle",
  error: null,
};

const name = "order";
const parentSelector = (state) => state[name];

const orderSlice = createSlice({
  name,
  initialState,
  reducers: {
    addOrderRequest: (state) => {
      state.status = "loading";
    },
    addOrderSuccess: (state, action) => {
      state.status = "succeeded";
      state.orders.push(action.payload);
      toast.success("Order placed successfully");
    },
    addOrderFailure: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
      toast.error("Failed to place order");
    },
  },
});

const getOrders = createSelector(parentSelector, (state) => state.orders);
const getOrderStatus = createSelector(parentSelector, (state) => state.status);
const getOrderError = createSelector(parentSelector, (state) => state.error);

const actions = {
  ...orderSlice.actions,
};

const selectors = {
  getOrders,
  getOrderStatus,
  getOrderError,
};

export const orderActions = { actions, selectors };
export default orderSlice;
