import React, { useState } from 'react';
import { IoIosArrowDown } from 'react-icons/io';
import { RiCloseLargeFill } from 'react-icons/ri';

const CollectionsHeader = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [isPriceDropdownOpen, setIsPriceDropdownOpen] = useState(false);
    const [priceRange, setPriceRange] = useState({ min: '', max: '' });
    const [isAvailabilityDropDonwnOpen, setIsAvailabilityDropDonwnOpen] = useState(false);
    const [availability, setAvailability] = useState('');


    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const togglePriceDropdown = () => {
        setIsPriceDropdownOpen(!isPriceDropdownOpen);
    };
    const toggleAvailabilityDropdown = () => {
        setIsAvailabilityDropDonwnOpen(!isAvailabilityDropDonwnOpen);
    };

    const handlePriceChange = (e) => {
        const { name, value } = e.target;
        setPriceRange({ ...priceRange, [name]: value });
    };

    const resetPriceChange = (e) => {
        setPriceRange({ min: '', max: '' });
    };
    const resretAvailability = (e) => {
        setAvailability('');
    };

    const handleAvailabilityChange = (e) => {
        setAvailability(e.target.value);
    };

    const handleBackdropClick = () => {
        setIsPriceDropdownOpen(false);
    };
    return (
        <div className="relative ">
            {/* Button for Small Screens */}
            {/* <div className="md:hidden mx-4 mt-4 z-50 flex items-center justify-between">
                <button
                    onClick={toggleSidebar}
                    className="text-primary  py-2 rounded-md flex items-center gap-x-2"
                >
                    <svg className="h-5 w-5 icon icon-filter" aria-hidden="true" focusable="false" role="presentation" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none">
                        <path fill-rule="evenodd" d="M4.833 6.5a1.667 1.667 0 1 1 3.334 0 1.667 1.667 0 0 1-3.334 0ZM4.05 7H2.5a.5.5 0 0 1 0-1h1.55a2.5 2.5 0 0 1 4.9 0h8.55a.5.5 0 0 1 0 1H8.95a2.5 2.5 0 0 1-4.9 0Zm11.117 6.5a1.667 1.667 0 1 0-3.334 0 1.667 1.667 0 0 0 3.334 0ZM13.5 11a2.5 2.5 0 0 1 2.45 2h1.55a.5.5 0 0 1 0 1h-1.55a2.5 2.5 0 0 1-4.9 0H2.5a.5.5 0 0 1 0-1h8.55a2.5 2.5 0 0 1 2.45-2Z" fill="currentColor"></path>
                    </svg>
                    Filter & Sort
                </button>

                <p className='text-secondary'>0 Products</p>

            </div> */}

            {/* Sidebar for Small Screens */}
            {/* <div
                className={`fixed top-0 right-0 w-80 z-50 h-full bg-white shadow-lg transform transition-transform duration-300 ${isSidebarOpen ? 'translate-x-0' : 'translate-x-full'}`}
            >
                <button
                    onClick={toggleSidebar}
                    className="fixed top-0 right-0   px-4 py-2 rounded-md mt-2 ml-2"
                >
                    <RiCloseLargeFill size={30} />
                </button>
                <div className="p-4">
                    <div className='text-center border-b pb-4'>
                        <h2>
                            Filter and sort
                        </h2>
                        <p className='text-secondary'>0 Products</p>
                    </div>
                    <div className="my-4">
                        <h3 className="font-semibold">Price</h3>
                        <div className="flex flex-col">
                            <label className="flex items-center mt-2">
                                <input type="radio" name="price" value="low-to-high" className="mr-2" />
                                Low to High
                            </label>
                            <label className="flex items-center mt-2">
                                <input type="radio" name="price" value="high-to-low" className="mr-2" />
                                High to Low
                            </label>
                        </div>
                    </div>
                    <div>
                        <h3 className="font-semibold">Availability</h3>
                        <div className="flex flex-col">
                            <label className="flex items-center mt-2">
                                <input type="checkbox" name="availability" value="in-stock" className="mr-2" />
                                In Stock
                            </label>
                            <label className="flex items-center mt-2">
                                <input type="checkbox" name="availability" value="out-of-stock" className="mr-2" />
                                Out of Stock
                            </label>
                        </div>
                    </div>
                </div>
            </div> */}

            {/* Header for Larger Screens */}
            {/* <div className="hidden md:flex items-center justify-between  p-4 bg-white ">

                            <div className='flex items-center '>
                                <p>Filter: </p>
                                <div className="relative">
                                    <button
                                        onClick={toggleAvailabilityDropdown}
                                        className="mx-4 flex items-center"
                                    >
                                        Availability <IoIosArrowDown />
                                    </button>
                                    {isAvailabilityDropDonwnOpen && (
                                        <div className="absolute w-96 bg-white shadow-lg rounded-xl border mt-2 z-10">
                                            <div className="p-4">
                                                <div className='flex items-center justify-between border-b p-4'>
                                                    <p className='text-secondary'> 0 Selected </p>
                                                    <button onClick={resretAvailability} className='text-primary underline ' >
                                                        Reset
                                                    </button>
                                                </div>
                                                <label className="flex items-center mt-2">
                                                    <input
                                                        type="checkbox"
                                                        name="availability"
                                                        value="in-stock"
                                                        className="mr-2"
                                                        onChange={handleAvailabilityChange}
                                                    />
                                                    In Stock
                                                </label>
                                                <label className="flex items-center mt-2">
                                                    <input
                                                        type="checkbox"
                                                        name="availability"
                                                        value="out-of-stock"
                                                        className="mr-2"
                                                        onChange={handleAvailabilityChange}
                                                    />
                                                    Out of Stock
                                                </label>
                                            </div>
                                        </div>
                                    )}
                                </div>



                                <div>
                                    <button onClick={togglePriceDropdown} className='mx-4 flex items-center'>Price <IoIosArrowDown /></button>
                                    {isPriceDropdownOpen && (
                                        <div className="absolute w-96 bg-white shadow-lg rounded-xl border  mt-2 z-10">
                                            <div className='flex items-center justify-between border-b p-4'>
                                                <h3> The highest price is £210.00 </h3>
                                                <button onClick={resetPriceChange} className='text-primary underline ' >
                                                    Reset
                                                </button>
                                            </div>
                                            <div className='flex items-center gap-x-2 p-4'>
                                                <h3>£</h3>
                                                <div class="relative  " data-twe-input-wrapper-init>
                                                    <input
                                                        type="number"
                                                        class=" peer block h-12 w-32 rounded-3xl border border-1 bg-transparent px-6 py-5 leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 peer-focus:text-primary data-[twe-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-white dark:placeholder:text-neutral-300 dark:autofill:shadow-autofill dark:peer-focus:text-primary [&:not([data-twe-input-placeholder-active])]:placeholder:opacity-0"
                                                        id="exampleFormControlInput1"
                                                        placeholder="Example label"
                                                        name="min"
                                                        value={priceRange.min}
                                                        onChange={handlePriceChange} />
                                                    <label
                                                        for="exampleFormControlInput1"
                                                        class="text-secondary pointer-events-none absolute left-5 top-0 mb-0 max-w-[90%] origin-[0_0] truncate pt-[0.37rem] leading-[1.6] text-neutral-500 transition-all duration-200 ease-out peer-focus:-translate-y-[0.9rem] peer-focus:scale-[0.8] peer-focus:text-primary peer-data-[twe-input-state-active]:-translate-y-[0.9rem] peer-data-[twe-input-state-active]:scale-[0.8] motion-reduce:transition-none dark:text-neutral-400 dark:peer-focus:text-primary"
                                                    >From
                                                    </label>
                                                </div>
                                                <h3>£</h3>
                                                <div class="relative  " data-twe-input-wrapper-init>
                                                    <input
                                                        type="number"
                                                        class=" peer block h-12 w-32 rounded-3xl border border-1 bg-transparent px-6 py-5 leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 peer-focus:text-primary data-[twe-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-white dark:placeholder:text-neutral-300 dark:autofill:shadow-autofill dark:peer-focus:text-primary [&:not([data-twe-input-placeholder-active])]:placeholder:opacity-0"
                                                        id="exampleFormControlInput2"
                                                        placeholder=" "
                                                        name="max"
                                                        value={priceRange.max}
                                                        onChange={handlePriceChange} />

                                                    <label
                                                        for="exampleFormControlInput2"
                                                        class="text-secondary pointer-events-none absolute left-5 top-0 mb-0 max-w-[90%] origin-[0_0] truncate pt-[0.37rem] leading-[1.6] text-neutral-500 transition-all duration-200 ease-out peer-focus:-translate-y-[0.9rem] peer-focus:scale-[0.8] peer-focus:text-primary peer-data-[twe-input-state-active]:-translate-y-[0.9rem] peer-data-[twe-input-state-active]:scale-[0.8] motion-reduce:transition-none dark:text-neutral-400 dark:peer-focus:text-primary"
                                                    >To
                                                    </label>
                                                </div>
                                            </div>

                                        </div>

                                    )}
                                </div>

                            </div>
                        
                        <div className='flex itmes-center '>
                            <p>Sort by: </p>
                            <select className=' cursor-pointer'>
                                <option selected>Best Selling</option>
                                <option >Alphabatically, A-Z</option>
                                <option >Alphabatically, Z-A</option>
                                <option >Price, high to low</option>
                                <option >Price, low to high</option>
                                <option >Date, old to new</option>
                                <option >Date, new to old</option>
                            </select>
                        <div className=' '>
                            <p className='text-secondary'>0 Products</p>
                        </div>
                        </div>
                
            </div> */}

            <div className="content p-4">
                {/* Your main content here */}
            </div>
        </div>
    );
}

export default CollectionsHeader;
