import React, { useEffect, useState } from "react";
import { RiCloseLargeFill } from "react-icons/ri";
import { CiSearch } from "react-icons/ci";
import Color from "../Cart/Color";
import Size from "../Cart/Size";
import Quantity from "../Cart/Quantity";
import AddToCart from "../Cart/AddToCart";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FETCH_PRODUCT } from "../../redux/types/product.types";
const ProductOptions = ({ setIsProductOptionsOpen, p_id}) => {
  const { id } = useParams();
  const categories = useSelector((state) => state.category.categories);
  const selectedCategory = categories.find((category) => category._id === id);
  const product = selectedCategory?.products?.find((product) => product._id === p_id);

  const isLoading = useSelector((state) => state.product.status === 'loading');
  const [quantity, setQuantity] = useState(1);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedColor, setSelectedColor] = useState(null);
  const [selectedSize, setSelectedSize] = useState(null);

  const [colors, setColors] = useState(null);
  const [sizes, setSizes] = useState(null);

  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    if (product) {
      setSelectedImage(product.images[0]);
      setColors(product?.color[0]);
      setSizes(product?.size[0]);
      setSelectedColor(product?.color[0]?.split(',')?.[0])
      setSelectedSize(product?.size[0]?.split(',')?.[0])
    }
  }, [product]);

  const sizeOrder = ["XS", "Small", "Medium", "Large", "XL", "Free Size"];

  const sortedSizes = [...new Set(sizes?.split(','))]?.sort(
    (a, b) => sizeOrder.indexOf(a) - sizeOrder.indexOf(b)
  );

  return (
    <div className="overflow-y-auto bg-white h-[90%] sm: md:h-3/4 w-[90%] md:w-2/3 rounded-xl  pb-10 ">
      <div className="flex justify-end h-[13%] md:h-[5%] ">
        <button
          onClick={() => {
            setIsProductOptionsOpen(false);
          }}
          className=" top-0 right-0 p-2 h-[40px] rounded-full border my-2 mr-2 "
        >
          <RiCloseLargeFill size={20} />
        </button>
      </div>
      <div className="flex flex-col md:flex-row w-full px-4">
        <div className="md:w-[35%] tracking-normal mb-2 md:mb-0">
          <img
            className="w-[100%] h-[100%] rounded-2xl "
            src={API_URL+selectedImage}
            alt={product?.name}
          />
        </div>

        <div className="md:ml-6 text-md md:w-[60%] text-start">
          <h1 className="text-4xl font-medium mb-2">{product?.name}</h1>
          <div className="mb-4">
            <p className="text-lg ">{`£ ${product?.price * quantity}`}</p>
            {/* <p className="text-sm">
              Tax included.{" "}
              <span className="underline text-primary cursor-pointer">
                Shipping
              </span>{" "}
              calculated at checkout.
            </p> */}
          </div>
          <Color 
          selectedColor={selectedColor}
          onColorSelect={setSelectedColor}
          colors={[...new Set(colors?.split(','))]}
          />
          <Size 
          selectedSize={selectedSize}
          onSizeSelect={setSelectedSize}
          sizes={sortedSizes}
          />
          <h1 className="mb-2 mt-2 text-sm">Quantity</h1>
          <Quantity onChange={setQuantity}/>
          <AddToCart product={product} quantity={quantity} selectedColor={selectedColor} selectedSize={selectedSize} />
        </div>
      </div>
    </div>
  );
};

export default ProductOptions;
