import React from "react";
import NewsLetter from "./NewsLetter";
import Credits from "./Credits";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <div className="border border-1 px-10 md:px-28 py-10 text-lg ">
        <div className=" flex flex-col md:flex-row  ">
          {/* Left side */}
          <div className="w-[50%]">
            <h1 className="text-2xl font-medium my-5  ">Shop</h1>
            <ul>
              {/* <li className="text-[16px] hover:underline text-gray-500 my-2 tracking-normal">
                <a href="#">Search</a>
              </li> */}
              <li className="text-[16px] hover:underline text-gray-500 my-2 tracking-normal">
                <a href="/">Home</a>
              </li>
              <li className="text-[16px] hover:underline text-gray-500 my-2 tracking-normal">
                <a href="about-us">About Us</a>
              </li>
              <Link to={"/termsandconditions"}>
                {" "}
                <li className="text-[16px] hover:underline text-gray-500 my-2 tracking-normal">
                  <a href="#">Terms & Conditions</a>
                </li>{" "}
              </Link>
              <li className="text-[16px] hover:underline text-gray-500 my-2 tracking-normal">
                <a href="refund-policy">Refund Policy</a>
              </li>
              <li className="text-[16px] hover:underline text-gray-500 my-2 tracking-normal">
                <a href="shipping-policy">Shipping Policy</a>
              </li>
            </ul>
          </div>

          {/* Right Side */}
          <div className="md:w-[50%]">
            <h1 className="text-2xl font-medium my-5 ">Enquires: </h1>
            <ul>
              <li className="text-[16px] text-gray-500 my-2 tracking-normal">
                <a href="#">Whatsapp: +44 7440 167549</a>
              </li>
              {/* <li className="text-[16px] text-gray-500 my-2 tracking-normal">
                <a href="#">Instagram: Direct Message</a>
              </li> */}
              <li className="text-[16px] text-gray-500 my-2 tracking-normal">
              <a href="mailto:info@rahaxpret.co.uk">
  Email:{" "}
  <span className="text-primary border-b border-primary">
    info@rahaxpret.co.uk
  </span>
</a>

              </li>
            </ul>
            <div>
              {/* <div className='flex items-center justify-end gap-2'>
                <FaFacebookSquare size={30} />
                <FaInstagram size={30} />
            </div> */}
            </div>
          </div>
        </div>
        {/* <NewsLetter /> */}
      </div>

      <div>
        <Credits />
      </div>
    </>
  );
};

export default Footer;
