import { createSelector, createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentUser: {},
  accessToken: "",
  auth: false,
  profileImageUploadStatus: "idle",
};

const name = "auth";
const parentSelector = (state) => state?.[name];

const authSlice = createSlice({
  name: name,
  initialState: initialState,
  reducers: {
    resetAuth: (state, action) => {
      return {
        ...state,
        auth: false,
        currentUser: {},
        accessToken: "",
      };
    },
    loginUserStatus: (state, action) => {
      return {
        ...state,
        auth: action.payload,
      };
    },
    setCurrentUserSlice: (state, action) => {
      return {
        ...state,
        currentUser: action.payload,
      };
    },
  },
});

const getCurrentUser = createSelector(parentSelector, (state) => {
  return state?.currentUser;
});
const getLoggedInStatus = createSelector(parentSelector, (state) => {
  return state?.auth;
});

const actions = {
  ...authSlice.actions,
};

const selectors = {
  getLoggedInStatus,
  getCurrentUser,
};

export const authActions = { actions, selectors };

export default authSlice;
