import React from "react";

import CollectionsHeader from "../../components/CollectionsPage/CollectionsHeader";
import Email from "../../components/NewsLetter/Email";
import ProductCard from "../../components/CollectionsPage/ProductCard";
import EidCollection from "../../components/Products/EidCollection/EidCollection.json";
import FormalWears from "../../components/Products/FormalWear/FormalWears.json";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

const Collections = () => {
  const { id } = useParams();
  const categories = useSelector((state) => state.category.categories);
  const selectedCategory = categories.find((category) => category._id === id);
  const API_URL = process.env.REACT_APP_API_URL;

  return (
    <div className="w-[100%] mx-auto px-[25px] sm:px-[50px] md:px-[100px]"> {/* padding added by sohaib */}
      <CollectionsHeader />
      <div className=" grid md:grid-cols-3 grid-cols-2 gap-3 sm:gap-6"> {/* changed gap 4 to 6 , for md changed the grid-col-4 to 3 */}
        {selectedCategory.products.map((product, index) => (
          <div key={index}>
            <ProductCard
              image={API_URL+product.images?.[0]}
              hoverImage={API_URL+product.images?.[1]}
              price={product.price}
              title={product.name}
              id={product?._id}
              inStock={product?.stock > 0} 
            />
          </div>
        ))}
      </div>
      {/* <Email /> */}
    </div>
  );
};

export default Collections;
