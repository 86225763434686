import React from "react";
import { CiSearch } from "react-icons/ci";
import { FaLongArrowAltRight } from "react-icons/fa";
import { Link } from "react-router-dom";

const Anouncement = () => {
  return (
    <a href="https://wa.me/447440167549">
      <div className="cursor-pointer px-4 flex items-center justify-center text-white py-2 bg-primary">
        <p className=" text-[10px] md:text-base">
          For Online Queries Call / Whatsapp: +447440167549.{" "}
        </p>
        <FaLongArrowAltRight size={20} className="ml-2" />
      </div>
    </a>
  );
};

export default Anouncement;
