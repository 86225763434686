// src/redux/sagas/order.saga.js

import { call, put, takeEvery } from "redux-saga/effects";
import { orderActions } from "../slice/order.slice";
import { addOrderService, sendMailService } from "../services/order.service";
import { ADD_ORDER } from "../types/order.types";
import { cartActions } from "../slice/cart.slice";

function* addOrderSaga(action) {
  try {
    yield put(orderActions.actions.addOrderRequest());
    const order = yield call(addOrderService, action.payload);
    yield put(orderActions.actions.addOrderSuccess(order));
    yield put(cartActions.actions.clearCart());
    const mail = yield call(sendMailService, order);
  } catch (error) {
    yield put(orderActions.actions.addOrderFailure(error.message));
  }
}

export function* watchOrderAsync() {
  yield takeEvery(ADD_ORDER, addOrderSaga);
}
