import { call, put, takeEvery } from "redux-saga/effects";
import {
  FETCH_CATEGORIES,
  FETCH_CATEGORY,
  ADD_CATEGORY,
  UPDATE_CATEGORY,
  DELETE_CATEGORY,
  FETCH_CATEGORIES_REQUEST,
} from "../types/category.types";
import { categoryActions } from "../slice/category.slice";
import {
  fetchCategoriesService,
  fetchCategoryService,
  addCategoryService,
  updateCategoryService,
  deleteCategoryService,
} from "../services/category.service";

function* fetchCategoriesSaga() {
  try {
    yield put(categoryActions.actions.fetchCategoriesRequest());
    const categories = yield call(fetchCategoriesService);
    yield put(categoryActions.actions.fetchCategoriesSuccess(categories));
  } catch (error) {
    yield put(categoryActions.actions.fetchCategoriesFailure(error.message));
  }
}

function* fetchCategorySaga(action) {
  try {
    const category = yield call(fetchCategoryService, action.payload);
    yield put(categoryActions.actions.fetchCategorySuccess(category));
  } catch (error) {
    yield put(categoryActions.actions.fetchCategoryFailure(error.message));
  }
}

function* addCategorySaga(action) {
  try {
    const category = yield call(addCategoryService, action.payload);
    yield put(categoryActions.actions.addCategorySuccess(category));
  } catch (error) {
    yield put(categoryActions.actions.addCategoryFailure(error.message));
  }
}

function* updateCategorySaga(action) {
  try {
    const category = yield call(updateCategoryService, action.payload);
    yield put(categoryActions.actions.updateCategorySuccess(category));
  } catch (error) {
    yield put(categoryActions.actions.updateCategoryFailure(error.message));
  }
}

function* deleteCategorySaga(action) {
  try {
    yield call(deleteCategoryService, action.payload);
    yield put(categoryActions.actions.deleteCategorySuccess(action.payload));
  } catch (error) {
    yield put(categoryActions.actions.deleteCategoryFailure(error.message));
  }
}

export function* watchCategoryAsync() {
  yield takeEvery(FETCH_CATEGORIES, fetchCategoriesSaga);
  yield takeEvery(FETCH_CATEGORY, fetchCategorySaga);
  yield takeEvery(ADD_CATEGORY, addCategorySaga);
  yield takeEvery(UPDATE_CATEGORY, updateCategorySaga);
  yield takeEvery(DELETE_CATEGORY, deleteCategorySaga);
}
