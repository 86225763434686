import React from "react";

const Color = ({ selectedColor, onColorSelect, colors }) => {
  const handleColorClick = (color) => {
    onColorSelect(color);
  };

  return (
    <div className="mt-2">
      <h1 className="mb-2 text-sm">Color</h1>
      <div className="flex overflow-x-auto space-x-2 scrollbar-hide">
        {colors?.map((color) => (
          <button
            key={color}
            onClick={() => handleColorClick(color)}
            className={`px-4 py-1 border rounded-3xl text-lg hover:border-black transition ease-in-out ${
              selectedColor === color
                ? "bg-black text-white"
                : "bg-white text-black"
            }`}
          >
            {color}
          </button>
        ))}
      </div>
    </div>
  );
};

export default Color;
