import { createSlice, createSelector } from '@reduxjs/toolkit';
import toast from 'react-hot-toast';

const initialState = {
  categories: [],
  category: null,
  status: 'idle',
  error: null,
};

const name = 'category';
const parentSelector = (state) => state[name];

const categorySlice = createSlice({
  name,
  initialState,
  reducers: {
    fetchCategoriesRequest: (state) => {
      state.status = 'loading';
    },
    fetchCategoriesSuccess: (state, action) => {
      state.status = 'succeeded';
      state.categories = action.payload;
    },
    fetchCategoriesFailure: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
    fetchCategoryRequest: (state) => {
      state.status = 'loading';
    },
    fetchCategorySuccess: (state, action) => {
      state.status = 'succeeded';
      state.category = action.payload;
    },
    fetchCategoryFailure: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
    addCategoryRequest: (state) => {
      state.status = 'loading';
    },
    addCategorySuccess: (state, action) => {
      state.status = 'succeeded';
      state.categories.push(action.payload);
      toast.success('Category added successfully');
    },
    addCategoryFailure: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
      toast.error('Failed to add category');
    },
    updateCategoryRequest: (state) => {
      state.status = 'loading';
    },
    updateCategorySuccess: (state, action) => {
      state.status = 'succeeded';
      const index = state.categories.findIndex((category) => category.id === action.payload.id);
      if (index !== -1) {
        state.categories[index] = action.payload;
        toast.success('Category updated successfully');
      }
    },
    updateCategoryFailure: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
      toast.error('Failed to update category');
    },
    deleteCategoryRequest: (state) => {
      state.status = 'loading';
    },
    deleteCategorySuccess: (state, action) => {
      state.status = 'succeeded';
      state.categories = state.categories.filter((category) => category.id !== action.payload);
      toast.success('Category deleted successfully');
    },
    deleteCategoryFailure: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
      toast.error('Failed to delete category');
    },
  },
});

const getCategoryState = createSelector(parentSelector, (state) => state);

const actions = {
  ...categorySlice.actions,
};

const selectors = {
  getCategoryState,
};

export const categoryActions = { actions, selectors };
export default categorySlice;
