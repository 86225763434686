import React from 'react';

const ImageModal = ({ isOpen, onClose, imageUrl }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="relative bg-white p-4 rounded-lg shadow-lg max-w-3xl w-full max-h-full overflow-auto">
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-gray-600 hover:text-gray-900"
          style={{border: "solid 1px black", paddingLeft:"3px", paddingRight:"3px", borderRadius:"20%", marginRight:"10px"}}
        >
          X
        </button>
        <img
          src="/images/chart.jpg"
          alt="Product"
          className="w-full h-auto max-h-[80vh] object-contain"
        />
      </div>
    </div>
  );
};

export default ImageModal;
