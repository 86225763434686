import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './Carousel.css'
const Carousel = () => {
  var settings = {
    dots: true,
    arrows: false,
    autoplay: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className=" w-full ">
      <Slider {...settings} className="w-full ">
        <div
          key={1}
          className="relative w-full h-72 md:h-96 lg:h-screen flex items-center justify-center rounded-[30px] overflow-hidden carousel-image-1 "
        >
          {/* <img
            src="../images/slider/1.jpg"
            alt="Background"
            className="absolute inset-0 w-full h-full object-cover border-green-500 border-4"
          /> */}
          <div className="absolute inset-0 flex items-center justify-center">
          <div className="bg-white bg-opacity-70 p-2 md:p-6 rounded-xl shadow-lg max-w-sm text-center  flex items-center justify-center flex-col md:w-[40%] lg:w-[25%]">
              <h1 className="text-xl md:text-4xl font-semibold py-3 md:py-6">
                Kaftans
              </h1>
              <p className="text-sm md:text-base text-center">
                Indulge into this Kaftans Collection
              </p>
              <Link to="/collections/668d0b65c49d041a95799685">
                <button className="text-base mt-3 md:mt-6 bg-primary cursor-pointer border-primary hover:border-2 text-white py-3 px-10 rounded-full focus:outline-none focus:shadow-outline">
                  Explore
                </button>
              </Link>
            </div>
          </div>
        </div>
        <div
          key={2}
          className="relative w-full h-72 md:h-96 lg:h-screen flex items-center justify-center overflow-hidden rounded-[30px] carousel-image-2 "
        >
          {/* <img
            src="../images/slider/2.jpg"
            alt="Background"
            className="absolute inset-0 w-full h-full object-cover"
          /> */}
          <div className="absolute inset-0 flex items-center justify-center ">
            <div className="bg-white bg-opacity-70 p-2 md:p-6 rounded-xl shadow-lg max-w-sm text-center flex items-center justify-center flex-col md:w-[40%] lg:w-[25%]">
              <h1 className="text-xl md:text-4xl font-semibold py-3 md:py-6">
                Raha's Premium
              </h1>
              <p className="text-sm md:text-base text-center">
                Indulge into this Raha's Premium
              </p>
              <Link to="/collections/668d0b7dc49d041a95799689">
                <button className="text-base mt-3 md:mt-6 bg-primary cursor-pointer border-primary hover:border-2 text-white py-3 px-10 rounded-full focus:outline-none focus:shadow-outline">
                  Explore
                </button>
              </Link>
            </div>
          </div>
        </div>
        <div
          key={3}
          className="relative w-full h-72 md:h-96 lg:h-screen flex items-center justify-center overflow-hidden rounded-[30px] carousel-image-3"
        >
          {/* <img
            src="../images/slider/3.png"
            alt="Background"
            className="absolute inset-0 w-full h-full object-cover"
          /> */}
          <div className="absolute inset-0 flex items-center justify-center">
            <div className="bg-white bg-opacity-70 p-2 md:p-6 rounded-xl shadow-lg max-w-sm text-center flex items-center justify-center flex-col md:w-[40%] lg:w-[25%]">
              <h1 className="text-xl md:text-4xl font-semibold py-3 md:py-6">
                New Drops
              </h1>
              <p className="text-sm md:text-base text-center">
                Indulge into this New Drops Collection
              </p>
              <Link to="/collections/668d0b31c49d041a9579967d">
                <button className="text-base mt-3 md:mt-6 bg-primary cursor-pointer border-primary hover:border-2 text-white py-3 px-10 rounded-full focus:outline-none focus:shadow-outline">
                  Explore
                </button>
              </Link>
            </div>
          </div>
        </div>
      </Slider>
    </div>
  );
};

export default Carousel;
