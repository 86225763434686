import React from 'react'
import Email from '../../components/NewsLetter/Email'

const FAQs = () => {
  return (
    <div className='px-5 mx-auto md:w-[60%] '>
      <h1 className='text-6xl my-12 font-medium'>
        FAQs
      </h1>
      <div className='mt-6 text-[#000000BF]' >
        <p className='my-6'>1. What's the delivery time frame?</p>
        <p className='my-2'>Within the UK, for a ready to dispatch item, delivery normally takes 3 working days. Next day delivery is also available as well as local collection.<br />We use Royal Mail courier primarily and only local delivery charges apply.<br />Production (including delivery) time for pre- ordered outfits can take 3-5 weeks depending on the dress. We also ship worldwide. Delivery time and shipping charges are variable depending on the destination and courier.
        </p>
        <p className='my-6'>2. Is there any refund/ exchange policy?</p>
        <p className='my-2'>We have a no refund or exchange policy. Most items contain hand worked details and are delicate pieces created by our experienced artisans, therefore there may be some minor variation. Be assured that we perform a thorough quality check of each item before it is sent out to the customer.</p>
      </div>

<div className='mt-10 p-5 flex items-center justify-center'>
  {/* <Email  /> */}
</div>
    </div>
  )
}

export default FAQs