import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { authActions } from "../../redux/slice/auth.slice";
import { USER_LOGIN } from "../../redux/types/auth.types";

const Login = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
  });
  const currentUser = useSelector(authActions.selectors.getCurrentUser)
  const navigate = useNavigate();

  useEffect(()=>{
    if(currentUser?.username){
      navigate("/");
    }
  }, [currentUser])

  const dispatch = useDispatch()

  const submitData = (data) => {
    dispatch({type: USER_LOGIN, payload: data})
    console.log("data", data);
  };
  return (
    <div>
      <div className=" ">
        <div className="bg-white my-12 p-8 w-full max-w-lg mx-auto">
          <h2 className="text-5xl mb-12 text-center">Login</h2>
          <form onSubmit={handleSubmit(submitData)}>
            <div class="relative my-5 " data-twe-input-wrapper-init>
              <input
                type="email"
                class="w-full peer block h-12 w-80 rounded-3xl border border-black border-1 bg-transparent px-6 pt-0 leading-[1.6] transition-all duration-200 ease-linear focus:placeholder:opacity-100 peer-focus:text-secondary"
                id="exampleFormControlInput1"
                placeholder="User Email"
                {...register("email", { required: true })}
              />
              {errors.email && (
                <span style={{ color: "red" }}>Email is mandatory </span>
              )}
            </div>

            <div class="relative my-5 " data-twe-input-wrapper-init>
              <input
                type="password"
                class="w-full peer block h-12 w-80 rounded-3xl border border-black border-1 bg-transparent px-6 pt-0 leading-[1.6] transition-all duration-200 ease-linear focus:placeholder:opacity-100 peer-focus:text-secondary"
                id="exampleFormControlInput2"
                placeholder="Paasword"
                {...register("password", { required: true })}
              />
              {errors.email && (
                <span style={{ color: "red" }}>Password is mandatory </span>
              )}
            </div>

            {/* <Link to={"/resetPassword"}>
              <a
                className=" border-b border-primary hover:border-b-2 inline-block align-baseline font-medium text-sm text-primary hover:text-md"
                href="#"
              >
                Forgot Password?
              </a>
            </Link> */}

            <div className="mt-12 flex flex-col gap-4 items-center justify-between">
              <input
                type="submit"
                value={"Sign In"}
                className="bg-primary cursor-pointer border-primary hover:border-2 text-white  py-3 px-6 rounded-full focus:outline-none focus:shadow-outline"
              />

              <Link to={"/register"}>
                <a
                  className="my-4 border-b border-primary hover:border-b-2 inline-block align-baseline font-medium text-sm text-primary hover:text-md"
                  href="#"
                >
                  Create Account
                </a>
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
