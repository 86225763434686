import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { format } from "date-fns";
import { authActions } from "../../redux/slice/auth.slice";
import { useDispatch, useSelector } from "react-redux";
import { LOG_OUT } from "../../redux/types/auth.types";

const SkeletonRow = () => (
  <tr className="animate-pulse">
    <td className="p-2 sm:p-4">
      <div className="h-4 bg-gray-300 rounded"></div>
    </td>
    <td className="p-2 sm:p-4">
      <div className="h-4 bg-gray-300 rounded"></div>
    </td>
    <td className="p-2 sm:p-4">
      <div className="h-4 bg-gray-300 rounded"></div>
    </td>
    <td className="p-2 sm:p-4">
      <div className="h-4 bg-gray-300 rounded"></div>
    </td>
    <td className="p-2 sm:p-4">
      <div className="h-4 bg-gray-300 rounded"></div>
    </td>
  </tr>
);

const SkeletonOrderDetails = () => (
  <div className="p-4">
    <table className="w-full text-left">
      <thead>
        <tr className="bg-gray-100 border-b">
          <th className="p-2 sm:p-4">Product Name</th>
          <th className="p-2 sm:p-4">Quantity</th>
          <th className="p-2 sm:p-4">Color</th>
          <th className="p-2 sm:p-4">Size</th>
          <th className="p-2 sm:p-4">Price</th>
          <th className="p-2 sm:p-4">Image</th>
        </tr>
      </thead>
      <tbody>
        {[...Array(3)].map((_, index) => (
          <tr key={index} className="animate-pulse">
            <td className="p-2 sm:p-4">
              <div className="h-4 bg-gray-300 rounded"></div>
            </td>
            <td className="p-2 sm:p-4">
              <div className="h-4 bg-gray-300 rounded"></div>
            </td>
            <td className="p-2 sm:p-4">
              <div className="h-4 bg-gray-300 rounded"></div>
            </td>
            <td className="p-2 sm:p-4">
              <div className="h-4 bg-gray-300 rounded"></div>
            </td>
            <td className="p-2 sm:p-4">
              <div className="h-4 bg-gray-300 rounded"></div>
            </td>
            <td className="p-2 sm:p-4">
              <div className="h-4 bg-gray-300 rounded"></div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

const API_URL = process.env.REACT_APP_API_URL;

const Account = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedOrder, setSelectedOrder] = useState(null);

  const navigate = useNavigate();

  const currentUser = useSelector(authActions.selectors.getCurrentUser);

  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch({ type: LOG_OUT });
    navigate("/");
  };

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const userId = currentUser?._id;
        const response = await axios.get(API_URL + `/api/orders/orders/${userId}`);
        setOrders(response.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, []);

  const handleOrderClick = (orderId) => {
    setSelectedOrder(selectedOrder === orderId ? null : orderId);
  };

  return (
    <div className="my-16 w-11/12 lg:w-5/6 mx-auto">
      <div className="my-8 flex justify-between items-center">
        <h1 className="text-2xl sm:text-3xl md:text-4xl font-semibold">Account</h1>
        <button
          onClick={handleLogout}
          className="text-sm sm:text-md md:text-lg text-blue-600 underline"
        >
          Log Out
        </button>
      </div>
      <div className="mb-12 flex flex-col md:flex-row justify-between">
        <div className="w-full md:w-[75%]">
          <h2 className="text-xl sm:text-2xl font-semibold mb-4 sm:mb-6">My Orders</h2>
          <div className="my-4 sm:my-8 p-4 sm:p-6 bg-white shadow-lg rounded-lg overflow-x-auto">
            <table className="w-full text-left border-collapse">
              <thead>
                <tr className="bg-gray-100 border-b">
                  <th className="p-2 sm:p-4 text-xs sm:text-sm whitespace-nowrap">Order ID</th>
                  <th className="p-2 sm:p-4 text-xs sm:text-sm whitespace-nowrap">No of Products</th>
                  <th className="p-2 sm:p-4 text-xs sm:text-sm whitespace-nowrap">Total Price</th>
                  <th className="p-2 sm:p-4 text-xs sm:text-sm whitespace-nowrap">Status</th>
                  <th className="p-2 sm:p-4 text-xs sm:text-sm whitespace-nowrap">Date Time</th>
                </tr>
              </thead>
              <tbody>
                {loading
                  ? [...Array(3)]?.map((_, index) => <SkeletonRow key={index} />)
                  : orders?.map((order) => (
                      <React.Fragment key={order._id}>
                        <tr
                          className="border-b hover:bg-gray-50 cursor-pointer"
                          onClick={() => handleOrderClick(order._id)}
                        >
                          <td className="p-2 sm:p-4 text-xs sm:text-sm whitespace-nowrap">{order._id}</td>
                          <td className="p-2 sm:p-4 text-xs sm:text-sm whitespace-nowrap">{order?.items?.length}</td>
                          <td className="p-2 sm:p-4 text-xs sm:text-sm whitespace-nowrap">
                            {order.total.toFixed(2)} GBP
                          </td>
                          <td className="p-2 sm:p-4 text-xs sm:text-sm whitespace-nowrap">{order.status}</td>
                          <td className="p-2 sm:p-4 text-xs sm:text-sm whitespace-nowrap">
                            {format(new Date(order.createdAt), "dd MMM yyyy, hh:mmaaa")}
                          </td>
                        </tr>
                        {selectedOrder === order._id && (
                          <tr className="bg-gray-50">
                            <td colSpan="5" className="p-2 sm:p-4">
                              {loading ? (
                                <SkeletonOrderDetails />
                              ) : (
                                <div className="p-2 sm:p-4 overflow-x-auto">
                                  <table className="w-full text-left">
                                    <thead>
                                      <tr className="bg-gray-100 border-b">
                                        <th className="p-2 sm:p-4 text-xs sm:text-sm whitespace-nowrap">Product Name</th>
                                        <th className="p-2 sm:p-4 text-xs sm:text-sm whitespace-nowrap" >Quantity</th>
                                        <th className="p-2 sm:p-4 text-xs sm:text-sm whitespace-nowrap">Color</th>
                                        <th className="p-2 sm:p-4 text-xs sm:text-sm whitespace-nowrap">Size</th>
                                        <th className="p-2 sm:p-4 text-xs sm:text-sm whitespace-nowrap">Price</th>
                                        <th className="p-2 sm:p-4 text-xs sm:text-sm whitespace-nowrap">Image</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {order?.items.map((item) => (
                                        <tr
                                          key={item._id}
                                          className="border-b hover:bg-gray-50"
                                        >
                                          <td className="p-2 sm:p-4 text-xs sm:text-sm whitespace-nowrap">
                                            {item?.product?.name || "Product no more"}
                                          </td>
                                          <td className="p-2 sm:p-4 text-xs sm:text-sm whitespace-nowrap">
                                            {item.quantity}
                                          </td>
                                          <td className="p-2 sm:p-4 text-xs sm:text-sm whitespace-nowrap">
                                            {item.color}
                                          </td>
                                          <td className="p-2 sm:p-4 text-xs sm:text-sm whitespace-nowrap">
                                            {item.size}
                                          </td>
                                          <td className="p-2 sm:p-4 text-xs sm:text-sm whitespace-nowrap">
                                            {item?.product?.price.toFixed(2)  || "Product no more"} GBP
                                          </td>
                                          <td className="p-2 sm:p-4 text-xs sm:text-sm whitespace-nowrap">
                                            <img
                                              src={API_URL + `${item?.product?.images?.[0]}`}
                                              alt={item?.product?.name}
                                              className="w-12 h-12 sm:w-16 sm:h-16 object-cover"
                                            />
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </div>
                              )}
                            </td>
                          </tr>
                        )}
                      </React.Fragment>
                    ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="w-full md:w-[20%] bg-white shadow-lg rounded-lg p-4 sm:p-6 mt-8 md:mt-0">
          <h2 className="text-xl sm:text-2xl font-semibold mb-2 sm:mb-4">Account Details</h2>
          <p className="text-sm sm:text-lg text-gray-700 mb-2">{currentUser?.username}</p>
        </div>
      </div>
    </div>
  );
};

export default Account;
