import React from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { ADD_ORDER } from "../../redux/types/order.types";
import { useDispatch } from "react-redux";

const PayPal = ({ amount, order }) => {
  const dispatch = useDispatch();

  return (
    <PayPalScriptProvider
      options={{
        "client-id":
          "AaL9shozqlhKo_Gumzf5N7ZLjD9ybXvGzHEX5jk7yA-8ictJ_pzis2CZfNd1O5DYoJ2TeEK6I-7Jo1dI",
      }}
    >
      <PayPalButtons
        style={{ layout: "vertical" }}
        createOrder={(data, actions) => {
          return actions.order.create({
            purchase_units: [
              {
                amount: {
                  value: amount, // Replace with the actual amount
                  currency: "GBP",
                },
              },
            ],
          });
        }}
        onApprove={(data, actions) => {
          return actions.order.capture().then((details) => {
            dispatch({ type: ADD_ORDER, payload: order });

            console.log(
              "Transaction completed by " + details.payer.name.given_name
            );
            // Handle successful transaction here
          });
        }}
        onError={(err) => {
          console.error("PayPal Checkout onError", err);
          // Handle errors here
        }}
      />
    </PayPalScriptProvider>
  );
};

export default PayPal;
