import React, { useState } from 'react'
import './ProductCard.css'
import ProductOptions from '../ProductOptions/ProductOptions';
import { Link } from 'react-router-dom';

const ProductCard = ({ image, hoverImage, price, title, id, inStock }) => {
  const [isProductOptionsOpen, setIsProductOptionsOpen] = useState(false);
  return (
    <div className="collection-item-wrapper mb-6" >
      <div className="collection-item">
      <Link to={`/products/${id}`}>

        <div className="image-container">
        {!inStock && <div className="sold-out-chip">Sold out</div>}

          <img src={image} alt={title} className="item-image" />
          <img src={hoverImage} alt={title} className="hover-image" />
        </div>
        </Link>

      </div>
      <p className="item-title text-lg md:text-3xl">{title}</p>
      <p className="item-price">£{price}</p>
      <button onClick={() => { setIsProductOptionsOpen(true) }} className='border border-primary w-full rounded-full p-3 text-primary hover:shadow-lg hover:border-2 transition-all duration-300 ease-in-out'>
        Choose Option</button>

      {isProductOptionsOpen && (
        <div className="fixed w-full h-screen flex items-center justify-center inset-0 z-50 bg-gray-900 bg-opacity-50">
          <ProductOptions setIsProductOptionsOpen={setIsProductOptionsOpen} p_id={id}/>
          <div
            className=" "
            onClick={() => setIsProductOptionsOpen(false)}
          ></div>
        </div>
      )}
    </div>
  )
}

export default ProductCard