import { createSelector, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";

const initialState = {
  items: [],
  status: "idle",
  error: null,
};

const name = "cart";
const parentSelector = (state) => state[name];

const cartSlice = createSlice({
  name,
  initialState,
  reducers: {
    addToCartRequest: (state) => {
      state.status = "loading";
    },
    addToCartSuccess: (state, action) => {
      state.status = "succeeded";
      const existingItem = state.items.find(
        (item) => item.product._id === action.payload.product._id
      );
      if (existingItem) {
        toast.error("Product already in cart");
      } else {
        state.items.push(action.payload);
        toast.success("Product added to cart successfully");
      }
    },
    addToCartFailure: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
    incrementQuantity: (state, action) => {
      const item = state.items.find(
        (item) => item.product._id === action.payload
      );
      if (item) {
        item.quantity += 1;
      }
    },
    decrementQuantity: (state, action) => {
      const item = state.items.find(
        (item) => item.product._id === action.payload
      );
      if (item && item.quantity > 1) {
        item.quantity -= 1;
      }
    },
    removeFromCart: (state, action) => {
      state.items = state.items.filter(
        (item) => item.product._id !== action.payload
      );
      toast.success("Product removed from cart");
    },
    clearCart: (state) => {
      state.items = [];
    },
  },
});

const getCartItems = createSelector(parentSelector, (state) => state.items);
const getCartStatus = createSelector(parentSelector, (state) => state.status);
const getCartError = createSelector(parentSelector, (state) => state.error);

const actions = {
  ...cartSlice.actions,
};

const selectors = {
  getCartItems,
  getCartStatus,
  getCartError,
};

export const cartActions = { actions, selectors };
export default cartSlice;
