import React from 'react';

const AboutUs = () => {
  return (
    <div className="container mx-auto px-4 py-8" style={{paddingLeft:"10%", paddingRight:"10%"}}>
      <h1 className="text-3xl font-semibold mb-4">About Us</h1>
      <p className="text-lg">
        Established in 2024, RAHA x PRET is a UK-based firm that specialises in fashionable,
        luxurious ready-to-wear clothes. Our manufacture is situated in Lahore and has
        simplistic designs with unparalleled fabric quality.
      </p>
      <p className="text-lg">
        We try to produce clothes that are both current and timeless, with a strong emphasis on
        fit and quality.
      </p>
      <p className="text-lg">
        We take tremendous satisfaction in the customer experience. Unlike other Pakistani
        designers, our goal is to create a seamless experience from beginning to end, allowing
        customers to feel safe in ordering an item and ensuring it is delivered within the time
        range specified.
      </p>
    </div>
  );
};

export default AboutUs;
