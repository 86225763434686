import React from 'react'
import { RiCloseLargeFill } from "react-icons/ri";
import { CiSearch } from "react-icons/ci";

const Search = ({ setIsSearchBarOpen }) => {
    return (
        <div className='bg-white h-52 w-full flex items-center justify-center ' >
         <div className='w-2/3 md:w-1/3'>
         <div class="relative my-3 mx-2  " data-twe-input-wrapper-init>
                <input
                    type="text"
                    class="w-full peer block h-12 w-80 rounded-3xl border border-1 bg-transparent px-6 py-5 leading-[1.6]  transition-all duration-200 ease-linear focus:placeholder:opacity-100 peer-focus:text-primary data-[twe-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-white dark:placeholder:text-neutral-300 dark:autofill:shadow-autofill dark:peer-focus:text-primary [&:not([data-twe-input-placeholder-active])]:placeholder:opacity-0"
                    id="exampleFormControlInput1"
                    placeholder="Example label" />
                <label
                    for="exampleFormControlInput1"
                    class="text-secondary pointer-events-none absolute left-5 top-1 mb-0 max-w-[90%] origin-[0_0] truncate pt-[0.37rem] leading-[1.6] text-neutral-500 transition-all duration-200 ease-out peer-focus:-translate-y-[0.9rem] peer-focus:scale-[0.8] peer-focus:text-primary peer-data-[twe-input-state-active]:-translate-y-[0.9rem] peer-data-[twe-input-state-active]:scale-[0.8] motion-reduce:transition-none dark:text-neutral-400 dark:peer-focus:text-primary"
                >Search
                </label>
                <CiSearch className='absolute right-4 top-1/2 transform -translate-y-1/2 text-secondary' />
            </div>

         </div>
            <button
                onClick={() => { setIsSearchBarOpen(false) }}
                className="text-secondary ">
                <RiCloseLargeFill size={20} />
            </button>
        </div>
    )
}

export default Search