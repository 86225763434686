import React from "react";
import { useSelector } from "react-redux";
import Carousel from "../../components/Carousel/Carousel";
import Products from "../../components/Products/Products";
import AboutUs from "../../components/AboutUs/AboutUs";
import { Box, Skeleton } from '@mui/material';
import ProductSkeleton from "./ProductSkeleton";

const Home = () => {
  const categories = useSelector((state) => state.category.categories);
  const isLoading = useSelector((state) => state.category.status === 'loading');

  return (
    <div>
      <Carousel />
      {isLoading ? (
        // Render 4 rows of skeleton loaders
        [...Array(4)].map((_, index) => (
          <Box className="collection-container" key={index}>
            <Skeleton variant="text" width="40%" height={24} sx={{ margin: '20px 0', marginLeft: "150px" }} />
            <Skeleton variant="text" width="20%" height={16} sx={{ margin: '10px 0 20px 0', marginLeft: "150px" }} />
            <Box className="w-[90%] mx-auto" sx={{ display: 'grid', gridTemplateColumns: { sm: 'repeat(4, 1fr)', xs: 'repeat(2, 1fr)' }, gap: 4 }}>
              {[...Array(4)].map((_, subIndex) => (
                <ProductSkeleton key={subIndex} />
              ))}
            </Box>
          </Box>
        ))
      ) : (
        categories.slice(0, 4).map((category) => (
          <Products
            id={category._id}
            heading={category.name}
            para={`Explore our latest ${category.name} collection.`}
            products={category.products}
          />
        ))
      )}
      <AboutUs />
    </div>
  );
};

export default Home;
