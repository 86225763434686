import React from 'react';
import { Box, Skeleton } from '@mui/material';

const ProductSkeleton = () => {
  return (
    <Box className="collection-item-wrapper" sx={{ margin: '10px 0' }}>
      <Box className="collection-item" sx={{ marginBottom: '10px' }}>
        <Box className="image-container" sx={{ position: 'relative', width: '100%', height: '250px', marginBottom: '10px' }}>
          <Skeleton variant="rectangular" width="100%" height="100%" />
          <Skeleton variant="rectangular" width="100%" height="100%" sx={{ position: 'absolute', top: 0, left: 0 }} />
        </Box>
      </Box>
      <Skeleton variant="text" width="80%" height={20} sx={{ marginBottom: '10px' }} />
      <Skeleton variant="text" width="40%" height={20} />
    </Box>
  );
};

export default ProductSkeleton;
