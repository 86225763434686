import { put, takeEvery } from "redux-saga/effects";
import { ADD_TO_CART, DECREMENT_QUANTITY, INCREMENT_QUANTITY } from "../types/cart.types";
import toast from "react-hot-toast";
import { cartActions } from "../slice/cart.slice";

export function* addToCartSaga(action) {
  try {
    yield put(cartActions.actions.addToCartSuccess(action.payload));
  } catch (error) {
    yield put(cartActions.addToCartFailure(error.message));
    toast.error("Failed to add product to cart");
  }
}

export function* incrementQuantitytSaga(action) {
  try {
    yield put(cartActions.actions.incrementQuantity(action.payload));
  } catch (error) {}
}

export function* decrementQuantitytSaga(action) {
  try {
    yield put(cartActions.actions.decrementQuantity(action.payload));
  } catch (error) {}
}

export function* watchCartAsync() {
  yield takeEvery(ADD_TO_CART, addToCartSaga);
  yield takeEvery(INCREMENT_QUANTITY, incrementQuantitytSaga);
  yield takeEvery(DECREMENT_QUANTITY, decrementQuantitytSaga);
}
