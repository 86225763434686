import React from 'react';

const ShippingPolicy = () => {
  return (
    <div className="container mx-auto px-4 py-8" style={{paddingLeft:"10%", paddingRight:"10%"}}>
      <h1 className="text-3xl font-semibold mb-4">Shipping Policy</h1>
      <p className="text-lg">
        Depending on the dress, the production (including delivery) period for pre-ordered
        clothing may take three to five weeks. We ship internationally as well. Shipping costs
        and delivery times vary based on the courier and the location.
      </p>
    </div>
  );
};

export default ShippingPolicy;
