import React from 'react'

const Credits = () => {
  return (
    <div className='my-6'>
        <div className='flex items-center justify-center mx-auto gap-2'>
            <img width={30} className='border' src='../images/payment/master.png'/>
            <img width={30} src='../images/payment/visa.png'/>
            <img width={30} src='../images/payment/master.png'/>
            <img width={30} src='../images/payment/visa.png'/>
            <img width={30} src='../images/payment/master.png'/>
            <img width={30} src='../images/payment/visa.png'/>
            <img width={30} src='../images/payment/master.png'/>
            <img width={30} src='../images/payment/visa.png'/>
            
        </div>
        <p className='text-center text-xs tracking-normal my-3'>© copyright Digiunction</p>
    </div>
  )
}

export default Credits