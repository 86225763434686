// src/redux/slice/product.slice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  product: null,
  status: 'idle',
  error: null,
};

const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    fetchProductRequest: (state) => {
      state.status = 'loading';
    },
    fetchProductSuccess: (state, action) => {
      state.status = 'succeeded';
      state.product = action.payload;
    },
    fetchProductFailure: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
  },
});

export const {
  fetchProductRequest,
  fetchProductSuccess,
  fetchProductFailure,
} = productSlice.actions;
export default productSlice;
