import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import MainHeader from "./components/Header/MainHeader";
import Home from "./pages/Home/Home";
import Footer from "./components/Footer/Footer";
import EidCollection from "./components/EidCollection/EidCollection";
import Nav from "./components/Nav/Nav";
import FAQs from "./pages/FAQs/FAQs";
import SingleProduct from "./pages/SingleProduct/SingleProduct";
import Collections from "./pages/Collections/Collections";
import Login from "./pages/Login/Login";
import Register from "./pages/Login/Register/Register";
import ResetPassword from "./pages/Login/ResetPassword/ResetPassword";
import CheckOut from "./pages/CheckOut/CheckOut";
import TermsAndConditions from "./pages/FooterPages/TermsAndConditions";
import Account from "./pages/Account/Account";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import ShippingPolicy from "./pages/ShippingPolicy/ShippingPolicy";
import RefundPolicy from "./pages/RefundPolicy/RefundPolicy";
import AboutUs from "./pages/AboutUs/AboutUs";
import WhatsAppButton from "./components/WhatsAppButton/WhatsAppButton";
import { FETCH_CATEGORIES } from "./redux/types/category.types";

const App = () => {
  return (
    <div>
      <Router>
        <ScrollToTop />
        <MainHeader />
        <div className="hidden md:flex md:items-center md:justify-center">
          <Nav />
        </div>
        <AppRoutes />
        <Footer />
        <WhatsAppButton />
      </Router>
    </div>
  );
};

const AppRoutes = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    // Dispatch the action every time the route changes
    dispatch({ type: FETCH_CATEGORIES });
  }, [location.pathname, dispatch]);

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/faqs" element={<FAQs />} />
      <Route path="/products/:id" element={<SingleProduct />} />
      <Route path="/collections/:id" element={<Collections />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/resetPassword" element={<ResetPassword />} />
      <Route path="/checkout" element={<CheckOut />} />
      <Route path="/termsandconditions" element={<TermsAndConditions />} />
      <Route path="/account" element={<Account />} />
      <Route path="/shipping-policy" element={<ShippingPolicy />} />
      <Route path="/refund-policy" element={<RefundPolicy />} />
      <Route path="/about-us" element={<AboutUs />} />
    </Routes>
  );
};

export default App;
