import React, { useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import "./ImageSlider2.css";

function ImageSlider2({ images, onImageClick }) {
  const [currentSlide, setCurrentSlide] = useState(1);
  const [totalSlides, setTotalSlides] = useState(0);
  const sliderRef = useRef(null);

  useEffect(() => {
    if (sliderRef.current) {
      setTotalSlides(sliderRef.current.props.children.length);
    }
  }, [sliderRef]);

  const settings = {
    arrows: false,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (current, next) => setCurrentSlide(next + 1),
    afterChange: (current) => setCurrentSlide(current + 1),
  };

  const API_URL = process.env.REACT_APP_API_URL;

  return (
    <div className="slider-container mt-0">
      <Slider
        ref={sliderRef}
        {...settings}
        className="px-0 pb-0 flex space-x-4"
      >
        {images.map((image, index) => {
          return (
            <div className="p-0">
              <img
                className="slider-images"
                src={`${API_URL}${image}`}
                alt="Slide 5"
                onClick={() => onImageClick(image)}
              />
            </div>
          );
        })}
      </Slider>
      <div className="md:hidden  flex justify-center items-center ">
        <SamplePrevArrow onClick={() => sliderRef.current.slickPrev()} />
        <span className="mx-4 text-secondary">
          {currentSlide} / {totalSlides}
        </span>
        <SampleNextArrow onClick={() => sliderRef.current.slickNext()} />
      </div>
    </div>
  );
}

export default ImageSlider2;

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div className="slick-arrow right-arrow" onClick={onClick}>
      <IoIosArrowForward />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div className="slick-arrow left-arrow" onClick={onClick}>
      <IoIosArrowBack />
    </div>
  );
}
