import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { IoIosArrowDown } from 'react-icons/io';
import { FETCH_CATEGORIES } from '../../redux/types/category.types'; // Ensure this path is correct
import style from './Nav.css';

const NavM = ({onLinkClick}) => {
  const dispatch = useDispatch();
  const categories = useSelector((state) => state.category.categories);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    dispatch({ type: FETCH_CATEGORIES });
  }, [dispatch]);

  const handleMouseEnter = () => {
    setIsDropdownOpen(true);
  };

  const handleMouseLeave = () => {
    setIsDropdownOpen(false);
  };

  return (
    <div>
      <div className="nav my-4">
        <ul className="menu flex flex-col md:flex-row md:items-center md:justify-center gap-4">
          <Link to={'/'} onClick={onLinkClick} >
            <li className="hover:underline hover:text-black text-md cursor-pointer">Home</li>
          </Link>
          
          {categories.map((category) => (
        <li key={category._id} className="hover:text-secondary text-md">
          <Link to={`/collections/${category._id}`} onClick={onLinkClick} >
            {category.name}
          </Link>
        </li>
      ))}
          <Link to={'/faqs'} onClick={onLinkClick} >
            <li className="hover:underline hover:text-black text-md cursor-pointer">FAQs</li>
          </Link>
        </ul>
      </div>
    </div>
  );
};

export default NavM;
