import React, { useState } from 'react';

const Quantity = ({ onChange }) => {
  const [quantity, setQuantity] = useState(1);

  const handleIncreaseQuantity = () => {
    const newQuantity = quantity + 1;
    setQuantity(newQuantity);
    onChange(newQuantity);
  };

  const handleDecreaseQuantity = () => {
    if (quantity > 1) {
      const newQuantity = quantity - 1;
      setQuantity(newQuantity);
      onChange(newQuantity);
    }
  };

  return (
    <div className=" ">
      <div className="w-36 flex items-center justify-between p-2 border rounded-3xl">
        <button className="px-2 text-2xl" onClick={handleDecreaseQuantity}>
          {' '}
          -{' '}
        </button>
        <p className="">{quantity}</p>
        <button className="px-2 text-2xl" onClick={handleIncreaseQuantity}>
          {' '}
          +{' '}
        </button>
      </div>
    </div>
  );
};

export default Quantity;
