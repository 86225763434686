import React from "react";
import "../CollectionsPage/ProductCard.css";

const ProductCard = ({ image, hoverImage, price, title, inStock }) => {
  return (
    <div className="collection-item-wrapper">
      <div className="collection-item">
        <div className="image-container">
          {!inStock && <div className="sold-out-chip">Sold out</div>}
          <img src={image} alt={title} className="item-image" />
          <img src={hoverImage} alt={title} className="hover-image" />
        </div>
      </div>
      <p className="item-title">{title}</p>
      <p className="item-price">£{price}</p>
    </div>
  );
};

export default ProductCard;
