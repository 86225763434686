import { call, put, takeEvery } from "redux-saga/effects";
import {
  loginServ,
  registerServ,
} from "../services/auth.service";
import { authActions } from "../slice/auth.slice";
import {
  LOG_OUT,
  USER_LOGIN,
  USER_REGISTER,
} from "../types/auth.types";

export function* loginSaga(action) {
  try {
    const user = yield loginServ(action.payload);
    if (user) {
      yield put(authActions.actions.setCurrentUserSlice(user?.user));
    } else {
    }
  } catch (err) {
    console.log("err", err);
  }
}

export function* registerSaga(action) {
  try {
    const user = yield call(registerServ, action.payload);
    if (user) {
      yield put(authActions.actions.setCurrentUserSlice(user));
    } else {
    }
    console.log("User registered:", user);
  } catch (err) {
    console.log("Registration error", err);
  }
}

export function* logoutSaga(action) {
  try {
      yield put(authActions.actions.setCurrentUserSlice({}));
    console.log("User Log out:");
  } catch (err) {
    console.log("Registration error", err);
  }
}

export function* watchAuthAsync() {
  yield takeEvery(USER_LOGIN, loginSaga);
  yield takeEvery(USER_REGISTER, registerSaga);
  yield takeEvery(LOG_OUT, logoutSaga)
}
