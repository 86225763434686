import React from 'react';

const RefundPolicy = () => {
  return (
    <div className="container mx-auto px-4 py-8" style={{paddingLeft:"10%", paddingRight:"10%"}}>
      <h1 className="text-3xl font-semibold mb-4">Refund Policy</h1>
      <p className="text-lg">
        We have no return or exchange policy. Most products have hand-worked features and
        are delicate pieces crafted by our trained artisans, therefore there may be some tiny
        variations.
      </p>
      <p className="text-lg">
        Be assured that each item is thoroughly quality checked before it is shipped to the buyer.
      </p>
    </div>
  );
};

export default RefundPolicy;
