import React from 'react';
import { useDispatch } from 'react-redux';
import { ADD_TO_CART } from '../../redux/types/cart.types';
import toast from 'react-hot-toast';

const AddToCart = ({ product, quantity, selectedColor, selectedSize }) => {
  const dispatch = useDispatch();

  console.log(product)
  const handleAddToCart = () => {
    if(product?.stock > 0){
      dispatch({ type: ADD_TO_CART, payload: { product, quantity, selectedColor, selectedSize } });
    }else{
      toast.error("Product out of stock")
    }
  };

  return (
    <div className="mt-4 flex flex-col justify-center items-center ">
      <button
        onClick={handleAddToCart}
        className="w-full my-2 border rounded-full border-primary text-primary py-2 hover:shadow-xl transition ease-in-out"
      >
        Add to cart
      </button>
      {/* <button className="w-2/3 bg-blue-500 text-white my-2 border rounded-full py-2 hover:shadow-lg transition-all ease-in-out">
        Shop with
      </button>
      <p className="text-sm underline cursor-pointer"> More payment options</p> */}
    </div>
  );
};

export default AddToCart;
