import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { USER_REGISTER } from "../../../redux/types/auth.types";
import { authActions } from "../../../redux/slice/auth.slice";
import { useNavigate } from "react-router-dom";

const Register = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
    },
  });

  const currentUser = useSelector(authActions.selectors.getCurrentUser)
  const navigate = useNavigate();

  useEffect(()=>{
    if(currentUser?.username){
      navigate("/");
    }
  }, [currentUser])

  const dispatch = useDispatch()

  const submitData = async (data) => {
    try {
      const fullName = `${data.firstName} ${data.lastName}`;
      const payload = { ...data, fullName, username:fullName };
      // Call the registerSaga action here with the payload
      console.log("payload", payload);

      dispatch({ type: USER_REGISTER, payload });
    } catch (err) {
      console.error("Registration error", err);
    }
  };

  return (
    <div>
      <div className="bg-white my-12 p-8 w-full max-w-lg mx-auto">
        <h2 className="text-5xl mb-12 text-center">Create Account</h2>
        <form onSubmit={handleSubmit(submitData)}>
          <div className="relative my-5">
            <input
              type="text"
              className="w-full peer block h-12 w-80 rounded-3xl border border-black border-1 bg-transparent px-6 pt-0 leading-[1.6] transition-all duration-200 ease-linear focus:placeholder:opacity-100 peer-focus:text-secondary"
              id="firstName"
              placeholder="First Name"
              {...register("firstName", { required: true })}
            />
            {errors.firstName && (
              <span style={{ color: "red" }}>First Name is mandatory</span>
            )}
          </div>

          <div className="relative my-5">
            <input
              type="text"
              className="w-full peer block h-12 w-80 rounded-3xl border border-black border-1 bg-transparent px-6 pt-0 leading-[1.6] transition-all duration-200 ease-linear focus:placeholder:opacity-100 peer-focus:text-secondary"
              id="lastName"
              placeholder="Last Name"
              {...register("lastName", { required: true })}
            />
            {errors.lastName && (
              <span style={{ color: "red" }}>Last Name is mandatory</span>
            )}
          </div>

          <div className="relative my-5">
            <input
              type="email"
              className="w-full peer block h-12 w-80 rounded-3xl border border-black border-1 bg-transparent px-6 pt-0 leading-[1.6] transition-all duration-200 ease-linear focus:placeholder:opacity-100 peer-focus:text-secondary"
              id="email"
              placeholder="Email"
              {...register("email", { required: true })}
            />
            {errors.email && (
              <span style={{ color: "red" }}>Email is mandatory</span>
            )}
          </div>

          <div className="relative my-5">
            <input
              type="password"
              className="w-full peer block h-12 w-80 rounded-3xl border border-black border-1 bg-transparent px-6 pt-0 leading-[1.6] transition-all duration-200 ease-linear focus:placeholder:opacity-100 peer-focus:text-secondary"
              id="password"
              placeholder="Password"
              {...register("password", { required: true })}
            />
            {errors.password && (
              <span style={{ color: "red" }}>Password is mandatory</span>
            )}
          </div>

          <div className="mt-12 flex flex-col gap-4 items-center justify-between">
            <input
              type="submit"
              value="Create"
              className="bg-primary cursor-pointer border-primary hover:border-2 text-white py-3 px-6 rounded-full focus:outline-none focus:shadow-outline"
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default Register;
