import { combineReducers } from "@reduxjs/toolkit";
import auth from "./auth.slice";
import cart from "./cart.slice";
import categorySlice from "./category.slice";
import productSlice from "./product.slice";
import orderSlice from "./order.slice";

const reducers = combineReducers({
  [auth.name]: auth.reducer,
  [cart.name]: cart.reducer,
  [categorySlice.name]: categorySlice.reducer,
  [productSlice.name]: productSlice.reducer,
  [orderSlice.name]: orderSlice.reducer
});

export default reducers;
