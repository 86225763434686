import { all } from 'redux-saga/effects'
import { watchAuthAsync } from './auth.saga'
import { watchCartAsync } from './cart.saga'
import { watchCategoryAsync } from './category.saga'
import { watchProductAsync } from './product.saga'
import { watchOrderAsync } from './order.saga'

export function* rootSaga() {
  yield all([
    watchAuthAsync(),
    watchCartAsync(),
    watchCategoryAsync(),
    watchProductAsync(),
    watchOrderAsync()
  ])
}
