import React from 'react'
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';


const ResetPassword = () => {
    const { register, handleSubmit, formState: { errors } } = useForm({
        defaultValues: {
            email: '',
        }
    });

    const submitData = (data) => {
        console.log("data", data)
    }
    return (
        <div>
            <div className='max-w-lg mx-auto my-12 flex flex-col items-center tracking-normal'>
                <h1 className='text-2xl md:text-[44px] my-4 font-medium '>Reset your password</h1>
                <p className='text-secondary text-center md:flex px-4 md:my-4 md:text-lg '>We will send you an email to reset your password</p>
                <form className='w-[85%] ' onSubmit={handleSubmit(submitData)}>
                    <div class="relative my-5 " data-twe-input-wrapper-init>
                        <input
                            type="email"
                            class="w-full peer block h-12 rounded-3xl border border-black border-1 bg-transparent px-6 pt-4 leading-[1.6]  transition-all duration-200 ease-linear focus:placeholder:opacity-100 peer-focus:text-secondary data-[twe-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-white dark:placeholder:text-neutral-300 dark:autofill:shadow-autofill dark:peer-focus:text-primary [&:not([data-twe-input-placeholder-active])]:placeholder:opacity-0"
                            id="exampleFormControlInput1"
                            placeholder="User Email"
                            // onChange={(e)=>setEmail(e.target.value)}
                            {...register('email', { required: true })}
                        />

                        <label
                            for="exampleFormControlInput1"
                            class="text-secondary pointer-events-none absolute left-5 top-2 mb-0 origin-[0_0] truncate pt-[0.37rem] leading-[1.6] text-neutral-500 transition-all duration-200 ease-out peer-focus:-translate-y-[0.9rem] peer-focus:scale-[0.8] peer-focus:text-secondary peer-data-[twe-input-state-active]:-translate-y-[0.9rem] peer-data-[twe-input-state-active]:scale-[0.8] motion-reduce:transition-none dark:text-neutral-400 dark:peer-focus:text-primary"
                        >Email
                        </label>
                        {errors.email && <span style={{ color: "red" }}>
                            Email is mandatory </span>}
                    </div>
                    <div className="mt-12 flex flex-col gap-4 items-center justify-between">
                        <input
                            type="submit"
                            value={'Sign In'}
                            className="bg-primary cursor-pointer border-primary hover:border-2 text-white  py-3 px-6 rounded-full focus:outline-none focus:shadow-outline" />
                        <Link to={'/login'} >

                            <a className="my-4 border-b border-primary hover:border-b-2 inline-block align-baseline font-medium text-sm text-primary hover:text-md">
                                Cancel
                            </a>

                        </Link>

                    </div>
                </form>
            </div>
        </div>
    )
}

export default ResetPassword