// src/redux/services/order.service.js

import axios from 'axios';
const API_URL = process.env.REACT_APP_API_URL

export const addOrderService = async (order) => {
  const response = await axios.post(API_URL+`/api/orders`, order);
  return response?.data;
};

export const sendMailService = async (order) => {
  const response = await axios.post(API_URL+`/api/email/send`, order);
  console.log(response)
  return response?.data;
};
