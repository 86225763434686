import React from 'react';

const Quantity = ({ quantity, onIncrement, onDecrement }) => {
  return (
    <div className="w-full sm:w-36 flex items-center justify-between border-[1px] border-black rounded-3xl py-2 mt-3 mb-3">
      <button className="px-2 text-2xl" onClick={onDecrement}> 
        {' '}
        -{' '}
      </button>
      <p className="">{quantity}</p>
      <button className="px-2 text-2xl" onClick={onIncrement}>
        {' '}
        +{' '}
      </button>
    </div>
  );
};

export default Quantity;
