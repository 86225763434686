import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL

export const loginServ = async (payload) => {
  try {
    const response = await axios.post(API_URL+`/api/users/login`, payload);
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const registerServ = async (payload) => {
  try {
    const response = await axios.post(`${API_URL}/api/users/signup`, payload);
    return response?.data;
  } catch (error) {
    throw error;
  }
};