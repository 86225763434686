import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { RiCloseLargeFill } from "react-icons/ri";
import Nav from "../Nav/Nav";
import { FaFacebookSquare, FaInstagram } from "react-icons/fa";
import NavM from "../Nav/NavM";

const MobileNavbar = ({ setIsSideBarOpen }) => {
  const sidebarRef = useRef(null);
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    setAnimate(true);

    // Close sidebar if clicked outside
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        handleClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClose = () => {
    setAnimate(false);
    setTimeout(() => {
      setIsSideBarOpen(false);
    }, 300); // Match this duration with the animation time
  };

  const handleMenuClick = () => {
    handleClose();
  };

  return (
    <div
      className={`fixed inset-0 z-50 flex bg-gray-900 bg-opacity-50 ${
        animate ? "opacity-100" : "opacity-0"
      } transition-opacity duration-300`}
    >
      <div
        ref={sidebarRef}
        className={`w-64 h-screen bg-white flex flex-col justify-between transform ${
          animate ? "translate-x-0" : "-translate-x-full"
        } transition-transform duration-300`}
      >
        <button onClick={handleClose} className="p-4 ">
          <RiCloseLargeFill size={30} />
        </button>

        <div
          className="flex p-4"
          style={{ position: "fixed", top: 50, width: "100%" }}
        >
          <NavM onLinkClick={handleMenuClick} />
        </div>
      </div>
      <div className="flex-1" onClick={handleClose}></div>
    </div>
  );
};

export default MobileNavbar;
