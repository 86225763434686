import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { IoIosArrowDown } from 'react-icons/io';
import { FETCH_CATEGORIES } from '../../redux/types/category.types'; // Ensure this path is correct
import style from './Nav.css';

const Nav = () => {
  const dispatch = useDispatch();
  const categories = useSelector((state) => state.category.categories);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  // useEffect(() => {
  //   dispatch({ type: FETCH_CATEGORIES });
  // }, []);

  const handleMouseEnter = () => {
    setIsDropdownOpen(true);
  };

  const handleMouseLeave = () => {
    setIsDropdownOpen(false);
  };

  return (
    <div>
      <div className="nav my-4">
        <ul className="menu flex flex-col md:flex-row md:items-center md:justify-center gap-4">
          <Link to={'/'}>
            <li className="hover:underline hover:text-black text-md cursor-pointer">Home</li>
          </Link>
          
            <li
              className="relative hover:underline hover:text-black text-md cursor-pointer flex justify-center items-center"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              Collections <IoIosArrowDown />
              {isDropdownOpen && (
                <ul className="absolute left-0 top-full pt-6 w-48 bg-white border border-gray-300 rounded-lg shadow-lg z-10">
                  {categories.map((category) => (
                    <Link to={`/collections/${category?._id}`}>
                    <div className="hover:text-secondary p-2" key={category._id}>
                      {category.name}
                    </div>
                    </Link>
                  ))}
                </ul>
              )}
            </li>
          <Link to={'/faqs'}>
            <li className="hover:underline hover:text-black text-md cursor-pointer">FAQs</li>
          </Link>
        </ul>
      </div>
    </div>
  );
};

export default Nav;
