import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FETCH_PRODUCT } from "../../redux/types/product.types";
import Size from "../../components/Cart/Size";
import Color from "../../components/Cart/Color";
import Quantity from "../../components/Cart/Quantity";
import AddToCart from "../../components/Cart/AddToCart";
import ImageSlider from "../../components/Carousel/ImageSlider";
import ImageSlider2 from "../../components/Carousel/ImageSlider2";
import Products from "../../components/Products/Products";
import ProductSkeleton from "./ProductSkeleton";
import ImageModal from "../../components/ImageModal/ImageModal";

const SingleProduct = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const product = useSelector((state) => state.product.product);
  const isLoading = useSelector((state) => state.product.status === "loading");
  const [quantity, setQuantity] = useState(1);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedColor, setSelectedColor] = useState(null);
  const [selectedSize, setSelectedSize] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false); // State for controlling modal visibility

  useEffect(() => {
    dispatch({ type: FETCH_PRODUCT, payload: id });
  }, [dispatch, id]);

  useEffect(() => {
    if (product) {
      setSelectedImage(product.images[0]);
      setSelectedColor(product.color?.[0]?.split(",")?.[0]);
      setSelectedSize(product.size?.[0]?.split(",")?.[0]);
    }
  }, [product]);

  if (isLoading) {
    return <ProductSkeleton />;
  }

  if (!product) {
    return <div>Product not found</div>;
  }

  const API_URL = process.env.REACT_APP_API_URL;

  // console.log([...new Set(product?.size?.[0]?.split(","))]);
  const sizeOrder = ["XS", "Small", "Medium", "Large", "XL", "Free Size"];

  const sortedSizes = [...new Set(product?.size?.[0]?.split(","))].sort(
    (a, b) => sizeOrder.indexOf(a) - sizeOrder.indexOf(b)
  );

  return (
    <div>
      <div className="w-full md:w-[90%] flex flex-col md:flex-row mx-auto my-4 px-5 md:px-5 tracking-normal">
        <div className="md:w-1/2 tracking-normal">
          <img
            className="hidden md:block w-[100%] rounded-2xl"
            style={{ objectFit: "cover" }}
            src={`${API_URL}${selectedImage}`}
            alt={product?.name}
          />
          <div className="hidden md:flex">
            <ImageSlider
              images={product?.images}
              onImageClick={setSelectedImage}
            />
          </div>
          <div className="md:hidden">
            <ImageSlider2
              images={product?.images}
              onImageClick={setSelectedImage}
            />
          </div>
        </div>
        <div className="md:ml-10 text-md md:w-1/2">
          <h1 className="text-4xl font-medium mb-6">{product?.name}</h1>
          <div>
            <p>{product?.subDescription}</p>
            <p
              className="md:my-4"
              dangerouslySetInnerHTML={{ __html: product?.description }}
            ></p>
            <p className="md:my-4">
              Model height: {product?.modelHeight}
              <br />
              Shirt length: {product?.shirtLength}
            </p>
          </div>
          <div className="mb-4">
            <p className="text-lg">£{product?.price} GBP</p>
          </div>
          <Color
            selectedColor={selectedColor}
            onColorSelect={setSelectedColor}
            colors={[...new Set(product?.color?.[0]?.split(","))]}
          />
          <Size
            selectedSize={selectedSize}
            onSizeSelect={setSelectedSize}
            sizes={sortedSizes}
          />
          <h1 className="mb-2 mt-6 text-sm">Quantity</h1>
          <Quantity onChange={setQuantity} />
          <AddToCart
            product={product}
            quantity={quantity}
            selectedColor={selectedColor}
            selectedSize={selectedSize}
          />
          {/* Button to open the modal */}
          <div className="mt-4 flex flex-col justify-center items-center ">
            <button
              onClick={() => setIsModalOpen(true)}
              className="mt-4 bg-gray-800 text-white py-2 px-4 rounded-md"
            >
              View Chart
            </button>
          </div>
        </div>
      </div>

      {/* Modal for viewing image */}
      <ImageModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        imageUrl={`${API_URL}${selectedImage}`}
      />
    </div>
  );
};

export default SingleProduct;
