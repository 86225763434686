import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL

export const fetchCategoriesService = async () => {
  const response = await axios.get(API_URL+`/api/categories`);
  return response.data;
};

export const fetchCategoryService = async (id) => {
  const response = await axios.get(API_URL+`/api/categories/${id}`);
  return response.data;
};

export const addCategoryService = async (category) => {
  const response = await axios.post(API_URL+`/api/categories`, category);
  return response.data;
};

export const updateCategoryService = async (category) => {
  const response = await axios.put(API_URL+`/api/categories/${category.id}`, category);
  return response.data;
};

export const deleteCategoryService = async (id) => {
  await axios.delete(API_URL+`/api/categories/${id}`);
};
