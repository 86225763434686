// src/redux/sagas/product.saga.js
import { call, put, takeEvery } from 'redux-saga/effects';
import {
  fetchProductRequest,
  fetchProductSuccess,
  fetchProductFailure,
} from '../slice/product.slice';
import { FETCH_PRODUCT } from '../types/product.types';
import { fetchProductById } from '../services/product.service';

function* fetchProductSaga(action) {
  try {
    yield put(fetchProductRequest());
    const product = yield call(fetchProductById, action.payload);
    yield put(fetchProductSuccess(product));
  } catch (error) {
    yield put(fetchProductFailure(error.message));
  }
}

export function* watchProductAsync() {
  yield takeEvery(FETCH_PRODUCT, fetchProductSaga);
}
